import { Injectable, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmComponent } from '../shared/components/confirm/confirm.component';
import { ExportComponent } from '../shared/components/export/export.component';
import { AlertdialogComponent } from '../shared/components/alertdialog/alertdialog.component';
import * as XLSX from 'xlsx-js-style';
import { MessageConfirmRquest } from '../shared/components/confirm/domain/message-confirm-request';
import { FormateoFechas, Parametros, Parametros_2 } from './domain/services-domain';
import { ListarTodosWebRequest, requestejecutivo } from '../proforma/domain/request/proforma_request';
import { ListParametros } from '../proforma/domain/response/proforma_response';
import { ProformaRepository } from '../proforma/domain/proforma.repository';
import { LinearScale } from 'chart.js';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Dropdown, DropdownItem } from 'primeng/dropdown';
import { getDate } from 'date-fns';
import { CryptoRepository } from '../interfaces/crypto-repository';



//@ts-ignore
@Injectable({
  providedIn: 'root'
})

export class UtilService {

  @Output() parametros: Parametros[] = [];
  @Output() parametros_v2: Parametros[] = [];
  constructor(private readonly dialog: MatDialog,
    private readonly notifier: MatSnackBar,
    private readonly bottomSheet: MatBottomSheet,
    private readonly proformaService: ProformaRepository,
    private readonly dialogService: DialogService,
    private readonly crypto: CryptoRepository
  ) {
    this.ngOnInit()
  }


  async Parametros(): Promise<Parametros[]> {
    const datos: Parametros[] = []
    try {
      const requestEjecutivo = <requestejecutivo>{}
      requestEjecutivo.entc_Codigo = 3

      const response = await this.proformaService.listarejecutivo(requestEjecutivo).toPromise()

      for (let i = 0; i < response.datos.result.length; i++) {
        const item: Parametros = {
          tipo: 'EJC',
          nombre: response.datos.result[i].nombreejecutivo,
          codigo: response.datos.result[i].codejecutivo.toString(),
          via: null,
          regimen: null,
          lineaNegocio: null,
          adicional1: null,
          adicional2: null,
          adicional3: null,
          pais: null,
          codpais: null
        };
        datos.push(item);
      }
    } catch (error) {

    }

    try {
      const request2 = <ListarTodosWebRequest>{}
      request2.tipo = null
      request2.codigo = null
      const response2 = await this.proformaService.ListarTodosWeb(request2).toPromise()

      for (let i = 0; i < response2.datos.result.length; i++) {
        const item: Parametros = {
          tipo: response2.datos.result[i].tipo,
          nombre: response2.datos.result[i].nombre.toUpperCase(),
          codigo: response2.datos.result[i].codigo.toString(),
          via: response2.datos.result[i].via,
          regimen: response2.datos.result[i].regimen,
          lineaNegocio: response2.datos.result[i].lineaNegocio,
          adicional1: response2.datos.result[i].adicional1,
          adicional2: response2.datos.result[i].adicional2,
          adicional3: response2.datos.result[i].adicional3,
          pais: response2.datos.result[i].pais,
          codpais: response2.datos.result[i].codpais
        };
        datos.push(item);
      }
    } catch (error) {

    }

    return datos
  }

  async ngOnInit(): Promise<void> {
    // this.parametros = await this.Parametros()//COMENTADO 2DBLE
  }

  async loadParametros(): Promise<Parametros[]> {
    const datos: Parametros[] = [];
    try {
      const requestEjecutivo: requestejecutivo = { entc_Codigo: 3 };
      const response = await this.proformaService.listarejecutivo(requestEjecutivo).toPromise();

      for (const item of response.datos.result) {
        datos.push({
          tipo: 'EJC',
          nombre: item.nombreejecutivo,
          codigo: item.codejecutivo.toString(),
          via: null,
          regimen: null,
          lineaNegocio: null,
          adicional1: null,
          adicional2: null,
          adicional3: null,
          pais: null,
          codpais: null
        });
      }
    } catch (error) {
      console.error('Error fetching ejecutivos:', error);
    }

    try {
      const request2: ListarTodosWebRequest = { tipo: null, codigo: null };
      const response2 = await this.proformaService.ListarTodosWeb(request2).toPromise();

      for (const item of response2.datos.result) {
        datos.push({
          tipo: item.tipo,
          nombre: item.nombre.toUpperCase(),
          codigo: item.codigo.toString(),
          via: item.via,
          regimen: item.regimen,
          lineaNegocio: item.lineaNegocio,
          adicional1: item.adicional1,
          adicional2: item.adicional2,
          adicional3: item.adicional3,
          pais: item.pais,
          codpais: item.codpais
        });
      }
    } catch (error) {
      console.error('Error fetching todos web:', error);
    }

    return datos;
  }
  async loadParametros_v2(): Promise<Parametros_2[]> {
    const datos: Parametros_2[] = [];
    try {
      const requestEjecutivo: requestejecutivo = { entc_Codigo: 3 };
      const response = await this.proformaService.listarejecutivo(requestEjecutivo).toPromise();

      for (const item of response.datos.result) {
        datos.push({
          id: null,
          tipo: 'EJC',
          nombre: item.nombreejecutivo,
          codigo: item.codejecutivo.toString(),
          via: null,
          regimen: null,
          lineaNegocio: null,
          adicional1: null,
          adicional2: null,
          adicional3: null,
          pais: null,
          codpais: null
        });
      }
    } catch (error) {
      console.error('Error fetching ejecutivos:', error);
    }

    try {
      const request2: ListarTodosWebRequest = { tipo: null, codigo: null };
      const response2 = await this.proformaService.ListarTodosWeb(request2).toPromise();

      for (const item of response2.datos.result) {
        datos.push({
          id: null,
          tipo: item.tipo,
          nombre: item.nombre.toUpperCase(),
          codigo: item.codigo.toString(),
          via: item.via,
          regimen: item.regimen,
          lineaNegocio: item.lineaNegocio,
          adicional1: item.adicional1,
          adicional2: item.adicional2,
          adicional3: item.adicional3,
          pais: item.pais,
          codpais: item.codpais
        });
      }
    } catch (error) {
      console.error('Error fetching todos web:', error);
    }

    return datos;
  }
  async getParametros(): Promise<Parametros[]> {
    // Opcional: Puedes manejar datos en caché aquí si es necesario
    if (this.parametros.length === 0) {
      this.parametros = await this.loadParametros();
    }
    return this.parametros;
  }

  // async getParametros(): Promise<Parametros[]> {
  //   setTimeout(() => {

  //   }, 1000);
  //   return this.parametros
  // }

  openModal(classComponent: any,
    options: { [s: string]: string | boolean | number },
    returnReference: boolean = false
  ): Observable<any> | any {

    const reference: MatDialogRef<typeof classComponent> =
      this.dialog.open(
        classComponent,
        options
      );

    if (returnReference) {

      return reference;

    } else {

      return reference.afterClosed();

    }

  }

  showMessage(message: string): void {
    this.notifier.open(message, null,
      {
        duration: 5000, horizontalPosition: 'end',
        verticalPosition: 'top', panelClass: ['blue-snackbar']
      })
  }

  showMessageError(message: string): void {
    this.notifier.open(message, null,
      {
        duration: 5000, horizontalPosition: 'end',
        verticalPosition: 'top', panelClass: ['message-error']
      })
  }

  confirm(message: string = ""): Observable<any> {

    const referenceConfirm = this.openModal(
      ConfirmComponent,
      {
        with: "320px",
        disableClose: true,
      },
      true
    );

    if (message) {

      referenceConfirm.componentInstance.message = message;
    }
    return referenceConfirm.afterClosed();

  }

  ShowMessageConfirm(data: MessageConfirmRquest): Observable<any> {

    const referenceConfirm = this.openModal(
      ConfirmComponent,
      {
        with: "320px",
        disableClose: true,
      },
      true
    );

    if (data.mensaje) {

      referenceConfirm.componentInstance.message = data.mensaje;
    }
    return referenceConfirm.afterClosed();

  }


  openSheet() {
    this.bottomSheet.open(ExportComponent);

  }

  formatearfecha(fecha, formato: FormateoFechas) {
    if (fecha === null || fecha === "") {
      return ""
    }
    var fechaFormateada = '';
    var fechaDate: Date
    if (fecha != null || fecha != '' || fecha != undefined) {
      fecha = fecha instanceof Date ? fecha : new Date(fecha);


      if (formato === 'dd/mm/yyyy') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + fecha.getFullYear();
      } else if (formato === 'mm/yyyy') {
        const nombresMeses = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
        const mesAbreviado = nombresMeses[fecha.getMonth()];
        fechaFormateada = mesAbreviado + '/' + fecha.getFullYear();
      } else if (formato === 'dd-mm-yyyy') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '-' + String(fecha.getMonth() + 1).padStart(2, '0') + '-' + fecha.getFullYear();
      } else if (formato === 'yyyy-mm-dd') {
        fechaFormateada = fecha.getFullYear() + '-' + String(fecha.getMonth() + 1).padStart(2, '0') + '-' + String(fecha.getDate()).padStart(2, '0');
      } else if (formato === 'yyyy/mm/dd') {
        fechaFormateada = fecha.getFullYear() + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + String(fecha.getDate()).padStart(2, '0');
      } else if (formato === 'hh:mm') {
        fechaFormateada = String(fecha.getHours()).padStart(2, '0') + ':' + String(fecha.getMinutes()).padStart(2, '0');
      } else if (formato === 'hh:mm:ss') {
        fechaFormateada = String(fecha.getHours()).padStart(2, '0') + ':' + String(fecha.getMinutes()).padStart(2, '0') + ':' + String(fecha.getSeconds()).padStart(2, '0');
      } else if (formato === 'dd/mm/yyyy hh:mm') {
        // fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0') + '/' + fecha.getFullYear() + ' ' +
        //   String(fecha.getUTCHours()).padStart(2, '0') + ':' +  // Cambio aquí
        //   String(fecha.getMinutes()).padStart(2, '0');
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' +
          String(fecha.getMonth() + 1).padStart(2, '0') + '/' +
          fecha.getFullYear() + ' ' +
          String(fecha.getHours()).padStart(2, '0') + ':' +
          String(fecha.getMinutes()).padStart(2, '0');
      } else if (formato === 'yyyy-MM-ddTHH:mm:ss') {
        fechaFormateada = fecha.toISOString().slice(0, 19).replace('T', ' ');
      } else if (formato === 'fechaInicioHora') {
        fechaDate = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 0, 0, 0);
      } else if (formato === 'fechaFinHora') {
        fechaDate = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 23, 59, 59);
      } else if (formato === 'dd/mm') {
        fechaFormateada = String(fecha.getDate()).padStart(2, '0') + '/' + String(fecha.getMonth() + 1).padStart(2, '0');
      } else if (formato === 'yyyy-mm-dd hh:mm:ss') {

        if (fecha == '' || fecha == undefined) {
          fechaFormateada = ''
        } else {


          const dia = String(fecha.getDate()).padStart(2, '0');
          const mes = String(fecha.getMonth() + 1).padStart(2, '0');
          const anio = fecha.getFullYear();
          const horas = String(fecha.getHours()).padStart(2, '0');
          const minutos = String(fecha.getMinutes()).padStart(2, '0');
          const segundos = String(fecha.getSeconds()).padStart(2, '0');

          fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos}:${segundos}`;
        }

      }
      else if (formato === 'fechaconT') {

        const dia = String(fecha.getDate()).padStart(2, '0');
        const mes = String(fecha.getMonth() + 1).padStart(2, '0');
        const anio = fecha.getFullYear();
        const horas = String(fecha.getHours()).padStart(2, '0');
        const minutos = String(fecha.getMinutes()).padStart(2, '0');
        const segundos = String(fecha.getSeconds()).padStart(2, '0');

        fechaFormateada = `${anio}-${mes}-${dia}T${horas}:${minutos}:${segundos}`;



      } else if (formato === 'dateString') {
        // Definir las opciones para formatear la fecha con el día de la semana
        const opciones: Intl.DateTimeFormatOptions = {
          weekday: 'long', // Muestra el nombre completo del día de la semana (ej. 'lunes', 'martes')
          day: '2-digit', // Muestra el día con dos dígitos (ej. '25')
          month: 'long', // Muestra el nombre completo del mes (ej. 'octubre')
          year: 'numeric' // Muestra el año completo (ej. '2024')
        };

        // Obtener la fecha formateada (incluyendo el día de la semana)
        fechaFormateada = fecha.toLocaleDateString('es-ES', opciones);
      }
    } else {
      fechaFormateada = ''
    }

    return fechaFormateada;
  }

  formateaFechaHoras(fecha, formato: string) {

    var fechaFormateada = '1900-01-01 00:00:00'
    if (fecha != "") {
      if (formato === 'fechaInicioHora') {
        fechaFormateada = fecha.getFullYear() + '-' +
          (fecha.getMonth() + 1).toString().padStart(2, '0') + '-' +
          fecha.getDate().toString().padStart(2, '0') + ' 00:00:00';
      } else if (formato === 'fechaFinHora') {
        fechaFormateada = fecha.getFullYear() + '-' +
          (fecha.getMonth() + 1).toString().padStart(2, '0') + '-' +
          fecha.getDate().toString().padStart(2, '0') + ' 23:59:59';
      }
    }
    return fechaFormateada
  }

  NuevoMensaje(titulo?: string, mensaje?: string, icono?: string, referencia?: any, cerrar: boolean = false, autocerrar: boolean = false, tiempo: number = 3000, tipo: string = 'ok') {

    const dialogRef = this.dialog.open(AlertdialogComponent, {
      data: { message: mensaje, icono: icono, titulo: titulo, tipo: tipo },
      panelClass: 'transparent-background'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (cerrar) {
        referencia?.close();  // El operador de opción segura (?.) evita errores si referencia es null o undefined
      }
    });

    if (autocerrar == true) {
      setTimeout(() => {
        dialogRef.close();
      }, tiempo);
    }
  }

  ShowMessage(options: {
    titulo?: string,
    mensaje?: string,
    icono?: string,
    cerrar?: boolean,
    referencia?: any,
    autocerrar?: boolean,
    tiempo?: number,
    tipo?: "ok" | "warning" | "error" | "",
    detalle?: string,
    pregunta?: {
      onAccept?: () => void,
      onReject?: () => void,
    },
    textoBtn?: {
      textoBtnOpcion1?: string,
      textoBtnOpcion2?: string,
    },
  }) {
    // Crear un objeto que contenga las opciones y utilice los valores predeterminados si no se proporcionan
    const showMessageOptions = {
      titulo: options.titulo || '',
      mensaje: options.mensaje || '',
      icono: options.icono || 'icono-predeterminado',
      tipo: options.tipo || 'ok',
      referencia: options.referencia,
      cerrar: options.cerrar || false,
      autocerrar: options.autocerrar || false,
      tiempo: options.tiempo || 3000,
      detalle: options.detalle || '',
      pregunta: options.pregunta || null,
      textoBtn: options.textoBtn || '',
    };

    // Abre el diálogo de alerta con las opciones proporcionadas
    const dialogRef = this.dialog.open(AlertdialogComponent, {
      data: {
        message: showMessageOptions.mensaje, icono: showMessageOptions.icono,
        titulo: showMessageOptions.titulo, tipo: showMessageOptions.tipo, detalle:
          showMessageOptions.detalle, pregunta: !!showMessageOptions.pregunta,
        textoBtn: showMessageOptions.textoBtn,
      },
      panelClass: 'transparent-background'
    });

    // Suscribirse al evento afterClosed del diálogo
    if (showMessageOptions.pregunta) {
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          showMessageOptions.pregunta?.onAccept?.();
        } else {
          showMessageOptions.pregunta?.onReject?.();
        }
      });
    } else {
      // Suscribirse al evento afterClosed del diálogo normal sin pregunta
      dialogRef.afterClosed().subscribe(result => {
        // Cerrar la referencia si se proporciona la opción de cerrar
        if (showMessageOptions.cerrar) {
          showMessageOptions.referencia?.close();
        }
      });
    }

    // Autocerrar el diálogo después del tiempo especificado si se proporciona la opción de autocerrar
    if (showMessageOptions.autocerrar) {
      setTimeout(() => {
        dialogRef.close();
      }, showMessageOptions.tiempo);
    }
  }

  formatearNumero(numero: number): string {
    // Verificar si el número tiene decimales
    const tieneDecimales = numero % 1 !== 0;

    // Convertir el número a string y separar las partes enteras y decimales
    const [parteEntera, parteDecimal] = numero.toFixed(2).split('.');

    // Agregar separadores de miles a la parte entera
    const parteEnteraFormateada = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Si el número original tiene decimales, mantenerlos, de lo contrario agregar '00'
    const parteDecimalFormateada = tieneDecimales ? '.' + parteDecimal : '.00';

    // Unir las partes y devolver el resultado
    return parteEnteraFormateada + parteDecimalFormateada;
  }

  ExportarExcel(colecciones: { data: any[], cabeceras: string[] }[], FileName: string = "documento.xlsx", footer: boolean = true, totales: any[] = []) {
    if (colecciones.length > 0) {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      for (let index = 0; index < colecciones.length; index++) {
        const data = colecciones[index].data;
        this.NewExcelSheet(data, ['ITEM', 'PRODUCTO', 'PRECIO'], wb, footer, totales);
      }

      XLSX.writeFile(wb, FileName);

    } else {
      console.log('No hay colecciones para exportar');
    }
  }
  ExportarExcel_V2(colecciones: { data: any[], cabeceras: string[] }[], FileName: string = "documento.xlsx", footer: boolean = true, totales: any[] = [], filasPrevias: string[][] = []) {
    if (colecciones.length > 0) {
      const wb: XLSX.WorkBook = XLSX.utils.book_new();

      // Iterar sobre cada colección de datos
      for (let index = 0; index < colecciones.length; index++) {
        const data = colecciones[index].data;
        this.NewExcelSheet_v2(data, ['ITEM', 'PRODUCTO', 'PRECIO'], wb, footer, totales, filasPrevias);
      }

      // Exportar el archivo
      XLSX.writeFile(wb, FileName);

    } else {
      console.log('No hay colecciones para exportar');
    }
  }


  // NewExcelSheet(dato: any[], cabeceras: string[], wb, footer: boolean = true) {
  //   console.log(dato)
  //   if (dato.length > 0) {

  //     if (dato.length > 1) {
  //       let datos: any[] = []

  //       datos = dato.slice()

  //       let inicioy = 0
  //       let iniciox = 0
  //       let iniciototales = 7

  //       //ESTABLECEMOS EL TAMAÑO DE LA HOJA
  //       const y = datos.length  // caso excepcion por las cabeceras
  //       const x = (datos.length > 0 ? Object.keys(datos[0]).length : 0);

  //       const data = [];
  //       for (let i = 1; i <= y + inicioy + 30; i++) { //PARA LA CANTIDAD DE FILAS SUMAR TITULOS + DATA + 2 ESPACIOS LIBRES
  //         const row = [];
  //         for (let j = 1; j <= x + 6 + iniciox; j++) { //PARA LA CANTIDAD DE COLUMNAS AUMENTAR 1 O 2
  //           row.push('');
  //         }
  //         data.push(row);
  //       }

  //       const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

  //       const colinicio = this.getExcelColumnName(iniciox)

  //       //CABECERAS
  //       XLSX.utils.sheet_add_json(ws, [datos[0]], { header: Object.keys(datos[0]), skipHeader: false, origin: colinicio + (inicioy + 1) });

  //       //CONTENIDO
  //       for (let index = 0; index < datos.length; index++) {
  //         XLSX.utils.sheet_add_json(ws, [datos[index]], { header: Object.keys(datos[index]), skipHeader: true, origin: colinicio + (inicioy + index + 2) });
  //       }


  //       let coorx = x - 1 + (iniciox)
  //       let coory = y + (inicioy)
  //       let totales = footer ? (coory + 1) : coory

  //       //ESTILOS FLETE
  //       for (var i in ws) {
  //         // console.log(ws[i]);
  //         if (typeof ws[i] != 'object') continue;
  //         let cell = XLSX.utils.decode_cell(i);

  //         //ESTILOS PARA TODA LA HOJA
  //         ws[i].s = {
  //           font: {
  //             name: 'Aptos Narrow',
  //             sz: 10,
  //           },
  //           fill: {
  //             patternType: 'solid',
  //             fgColor: { rgb: 'ffffff' },
  //           },
  //           alignment: {
  //             vertical: 'center',
  //             horizontal: 'center',
  //             wrapText: false,
  //           }
  //         };

  //         if (cell.r <= totales && cell.c <= coorx && cell.r >= inicioy && cell.c >= iniciox) {
  //           ws[i].s.border = {
  //             top: { style: 'medium' },
  //             bottom: { style: 'medium' },
  //             left: { style: 'medium' },
  //             right: { style: 'medium' },
  //           }

  //           if (cell.r == inicioy) {
  //             ws[i].s.fill = {
  //               patternType: 'solid',
  //               fgColor: { rgb: '156082' },
  //             }
  //             ws[i].s.font = {
  //               name: 'Aptos Narrow',
  //               sz: 10,
  //               bold: true,
  //               color: { rgb: 'ffffff' }
  //             }
  //             ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' }
  //             ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' }
  //           }
  //           else if (cell.r == totales) {
  //             // if (cell.c == iniciox) {
  //             //   ws[i].v = 'TOTALES'
  //             // }

  //             // ws[i].z = '#,##0.00';

  //             // for (var k = iniciototales; k <= (coorx - 2); k++) { // -2 caso especifico
  //             //   const col = this.getExcelColumnName(k);
  //             //   XLSX.utils.sheet_set_array_formula(ws, {
  //             //     s: { c: k, r: totales }, // Coordenada de inicio
  //             //     e: { c: k, r: totales }  // Coordenada de fin
  //             //   }, "SUM(" + col + "" + (inicioy + 2) + ":" + col + "" + (totales) + ")",)
  //             // }

  //             // ws[i].s.font.bold = { bold: true }

  //             ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' }
  //             ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' }
  //             ws[i].s.border.top = { style: 'none' }
  //             ws[i].s.border.bottom = { style: 'thin' }
  //           } else {
  //             ws[i].s.border = {
  //               top: { style: 'none' },
  //               bottom: { style: 'none' },
  //               left: cell.c == iniciox ? { style: 'medium' } : { style: 'thin' },
  //               right: cell.c == coorx ? { style: 'medium' } : { style: 'thin' },
  //             }
  //           }
  //         }
  //       }

  //       const colsConfig = Array.from({ length: coorx + 1 }, (_, index) => ({
  //         wch: index === 0 ? 20 : (index === 0 ? 20 : (index === 1 ? 27 : (index === 2 ? 50 : (index === 5 ? 50 : 18))))
  //       }));

  //       ws['!cols'] = colsConfig;

  //       const rangoflete1 = { s: { r: totales, c: iniciox }, e: { r: totales, c: iniciox + (iniciototales - 1) } };
  //       // ws['!merges'] = [rangoflete1];

  //       XLSX.utils.book_append_sheet(wb, ws);
  //     }

  //   } else {
  //     console.log('El array está vacío - no hay datos para exportar');
  //   }
  // }
  NewExcelSheet(dato: any[], cabeceras: string[], wb, footer: boolean = true, coltotales: number[] = []) {
    console.log(dato);
    if (dato.length > 0) {

      // Creamos una copia de los datos para trabajar con ellos
      let datos: any[] = dato.slice();

      let inicioy = 0;
      let iniciox = 0;
      let iniciototales = 7;

      // Establecemos las dimensiones de la hoja (filas y columnas)
      const y = datos.length;  // Cantidad de filas
      const x = (datos.length > 0 ? Object.keys(datos[0]).length : 0);  // Cantidad de columnas

      // Inicializamos la estructura de la hoja con datos vacíos
      const data = [];
      for (let i = 1; i <= y + inicioy + 30; i++) { // Aseguramos que haya suficiente espacio para filas y títulos
        const row = [];
        for (let j = 1; j <= x + 6 + iniciox; j++) { // Aumentamos las columnas si es necesario
          row.push('');
        }
        data.push(row);
      }

      // Creamos la hoja a partir de la estructura de datos vacíos
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

      const colinicio = this.getExcelColumnName(iniciox);

      // Agregamos las cabeceras si tenemos datos
      XLSX.utils.sheet_add_json(ws, [datos[0]], { header: Object.keys(datos[0]), skipHeader: false, origin: colinicio + (inicioy + 1) });

      // Agregamos el contenido (si hay más de una fila)
      if (datos.length > 1) {
        for (let index = 1; index < datos.length; index++) {
          XLSX.utils.sheet_add_json(ws, [datos[index]], { header: Object.keys(datos[index]), skipHeader: true, origin: colinicio + (inicioy + index + 2) });
        }
      }

      // Calculamos las coordenadas de la última celda
      let coorx = x - 1 + (iniciox);
      let coory = y + (inicioy);
      let totales = footer ? (coory + 1) : coory;

      // Estilizamos la hoja
      for (var i in ws) {
        if (typeof ws[i] != 'object') continue;
        let cell = XLSX.utils.decode_cell(i);

        // Estilo general para todas las celdas
        ws[i].s = {
          font: {
            name: 'Aptos Narrow',
            sz: 10,
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'ffffff' },
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: false,
          }
        };

        // Configuración de bordes
        if (cell.r <= totales && cell.c <= coorx && cell.r >= inicioy && cell.c >= iniciox) {
          ws[i].s.border = {
            top: { style: 'medium' },
            bottom: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' },
          };

          // Estilo de cabecera
          if (cell.r == inicioy) {
            ws[i].s.fill = {
              patternType: 'solid',
              fgColor: { rgb: '156082' },
            };
            ws[i].s.font = {
              name: 'Aptos Narrow',
              sz: 10,
              bold: true,
              color: { rgb: 'ffffff' },
            };
            ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' };
          }
          // Estilo de totales (si corresponde)
          else if (cell.r == totales) {
            ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.top = { style: 'none' };
            ws[i].s.border.bottom = { style: 'thin' };

            if (coltotales.length > 0) {

              for (var k = 0; k <= coorx; k++) {
                const col = this.getExcelColumnName(k);
                const estaAlMenosUno = coltotales.some(valor => coltotales.includes(k));

                if (estaAlMenosUno == true) {

                  XLSX.utils.sheet_set_array_formula(ws, {
                    s: { c: k, r: totales }, // Coordenada de inicio
                    e: { c: k, r: totales }  // Coordenada de fin
                  }, "SUM(" + col + (inicioy + 2) + ":" + col + "" + (totales) + ")",)

                }





              }
            }



          }
          // Estilo de celdas de contenido
          else {
            ws[i].s.border = {
              top: { style: 'none' },
              bottom: { style: 'none' },
              left: cell.c == iniciox ? { style: 'medium' } : { style: 'thin' },
              right: cell.c == coorx ? { style: 'medium' } : { style: 'thin' },
            };
          }
        }
      }

      // Configuración de las columnas (anchos de columna)
      const colsConfig = Array.from({ length: coorx + 1 }, (_, index) => ({
        wch: index === 0 ? 20 : (index === 1 ? 27 : (index === 2 ? 50 : (index === 5 ? 50 : 18)))
      }));
      ws['!cols'] = colsConfig;

      // Añadimos la hoja al libro de trabajo
      XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

    } else {
      console.log('El array está vacío - no hay datos para exportar');
    }
  }
  NewExcelSheet_v2(dato: any[], cabeceras: string[], wb: XLSX.WorkBook, footer: boolean = true, coltotales: number[] = [], filasPrevias: string[][] = []) {
    console.log(dato);
    if (dato.length > 0) {

      // Creamos una copia de los datos para trabajar con ellos
      let datos: any[] = dato.slice();

      let inicioy = 0;
      let iniciox = 0;
      let filaIndex = 0;  // Se inicializa el índice de filas previas
      // let iniciototales = tablaDesdeFila + 2;  // Ajustamos para los totales (después de la tabla)

      // Establecemos las dimensiones de la hoja (filas y columnas)
      const y = datos.length;  // Cantidad de filas
      const x = (datos.length > 0 ? Object.keys(datos[0]).length : 0);  // Cantidad de columnas

      // Inicializamos la estructura de la hoja con datos vacíos
      const data = [];
      for (let i = 1; i <= y + inicioy + 30; i++) { // Aseguramos que haya suficiente espacio para filas y títulos
        const row = [];
        for (let j = 1; j <= x + 6 + iniciox; j++) { // Aumentamos las columnas si es necesario
          row.push('');
        }
        data.push(row);
      }

      // Creamos la hoja a partir de la estructura de datos vacíos
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

      // Agregar las filas previas, con líneas vacías entre ellas
      filasPrevias.forEach(fila => {
        // Si fila es un arreglo, lo agregamos tal cual
        XLSX.utils.sheet_add_aoa(ws, [fila], { origin: { r: filaIndex, c: 0 } });
        filaIndex++;  // Avanzamos al siguiente índice
      });

      // Agregamos las cabeceras en la fila donde comienza la tabla
      const colinicio = this.getExcelColumnName(iniciox);
      XLSX.utils.sheet_add_json(ws, [datos[0]], { header: Object.keys(datos[0]), skipHeader: false, origin: colinicio + (inicioy + filaIndex + 1) });

      // Agregamos el contenido (si hay más de una fila)
      if (datos.length > 1) {
        for (let index = 1; index < datos.length; index++) {
          XLSX.utils.sheet_add_json(ws, [datos[index]], { header: Object.keys(datos[index]), skipHeader: true, origin: colinicio + (inicioy + filaIndex + index + 2) });
        }
      }

      // Calculamos las coordenadas de la última celda
      let coorx = x - 1 + (iniciox);
      let coory = y + (inicioy) + filaIndex;
      let totalesCoord = footer ? (coory + 1) : coory;

      // Estilizamos la hoja
      for (var i in ws) {
        if (typeof ws[i] != 'object') continue;
        let cell = XLSX.utils.decode_cell(i);

        // Estilo general para todas las celdas
        ws[i].s = {
          font: {
            name: 'Aptos Narrow',
            sz: 10,
          },
          fill: {
            patternType: 'solid',
            fgColor: { rgb: 'ffffff' },
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: false,
          }
        };

        // Configuración de bordes
        if (cell.r <= totalesCoord && cell.c <= coorx && cell.r >= inicioy && cell.c >= iniciox) {
          ws[i].s.border = {
            top: { style: 'medium' },
            bottom: { style: 'medium' },
            left: { style: 'medium' },
            right: { style: 'medium' },
          };

          // Estilo de cabecera
          if (cell.r == (inicioy + filaIndex)) {  // La cabecera se pinta en la fila donde comienza la tabla
            ws[i].s.fill = {
              patternType: 'solid',
              fgColor: { rgb: '156082' },
            };
            ws[i].s.font = {
              name: 'Aptos Narrow',
              sz: 10,
              bold: true,
              color: { rgb: 'ffffff' },
            };
            ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' };
          }
          // Estilo de totales (si corresponde)
          else if (cell.r == totalesCoord) {
            ws[i].s.border.left = cell.c == iniciox ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.right = cell.c == coorx ? { style: 'medium' } : { style: 'thin' };
            ws[i].s.border.top = { style: 'none' };
            ws[i].s.border.bottom = { style: 'thin' };
          }
          // Estilo de celdas de contenido
          else {
            ws[i].s.border = {
              top: { style: 'none' },
              bottom: { style: 'none' },
              left: cell.c == iniciox ? { style: 'medium' } : { style: 'thin' },
              right: cell.c == coorx ? { style: 'medium' } : { style: 'thin' },
            };
          }
        }
      }

      // Configuración de las columnas (anchos de columna)
      const colsConfig = Array.from({ length: coorx + 1 }, (_, index) => ({
        wch: index === 0 ? 20 : (index === 1 ? 27 : (index === 2 ? 50 : (index === 5 ? 50 : 18)))
      }));
      ws['!cols'] = colsConfig;

      // Añadimos la hoja al libro de trabajo
      XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

    } else {
      console.log('El array está vacío - no hay datos para exportar');
    }
  }


  getExcelColumnName(index: number): string {
    let columnName = "";
    while (index >= 0) {
      columnName = String.fromCharCode(index % 26 + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  }

  show(classComponent: any, ref: DynamicDialogRef, options: DynamicDialogConfig) {

    ref = this.dialogService.open(classComponent, options);

    ref.onMaximize.subscribe((value) => {
    });


    return ref
  }

  getMeses(): dropdownItems[] {
    const MESES: dropdownItems[] = [
      { label: 'TODOS', value: 'TODOS' },
      { label: 'ENERO', value: '01' },
      { label: 'FEBRERO', value: '02' },
      { label: 'MARZO', value: '03' },
      { label: 'ABRIL', value: '04' },
      { label: 'MAYO', value: '05' },
      { label: 'JUNIO', value: '06' },
      { label: 'JULIO', value: '07' },
      { label: 'AGOSTO', value: '08' },
      { label: 'SEPTIEMBRE', value: '09' },
      { label: 'OCTUBRE', value: '10' },
      { label: 'NOVIEMBRE', value: '11' },
      { label: 'DICIEMBRE', value: '12' }
    ]

    return MESES
  }

  getAnios(): dropdownItems[] {

    const anio: number = (new Date().getFullYear())

    const ANIOS: dropdownItems[] = [
      { label: (anio).toString(), value: anio },
      { label: (anio - 1).toString(), value: anio - 1 },
      { label: (anio - 2).toString(), value: anio - 2 },
      { label: (anio - 3).toString(), value: anio - 3 },
      { label: (anio - 4).toString(), value: anio - 4 },
      { label: (anio - 5).toString(), value: anio - 5 },
      { label: (anio - 6).toString(), value: anio - 6 },
      { label: (anio - 7).toString(), value: anio - 7 },
    ]

    return ANIOS
  }

  ExportExcel(options: optionExcelExport) {

    const ExcelJS = require('exceljs');


    if (options.sheets.length > 0) {

      // SE CREA EL EXCEL
      const workbook = new ExcelJS.Workbook();

      options.sheets.forEach((e, index) => {

        if (e.data.length > 0) {

          // SE CREA LA HOJA Y SE AGREGA SU NOMBRE
          const worksheet = workbook.addWorksheet(e.sheetName);

          // Creamos una copia de los datos para trabajar con ellos
          let datos = e.data.slice();

          // Posiciona los datos de la hoja incluye las cabeceras
          const inicioy = e.coordinates == undefined ? 1 : e.coordinates.y
          const iniciox = e.coordinates == undefined ? 1 : e.coordinates.x

          // Ubica la posicion inicial de la data
          const datax = iniciox + 1

          // Definimos las cantidades de filas y columnas de la data
          const county = datos.length
          const countx = Object.keys(datos[0]).length

          const rowTotaly = e.totalY == undefined ? false : e.totalY.status

          // Para los totales
          const totalx = countx + 1
          const totaly = county + iniciox + 1

          const footer = true

          // FILA PARA LOS TOTALES
          if (rowTotaly) {

            const headers = Object.keys(datos[0]);

            // Creamos una fila vacía para los totales con las mismas claves pero con valores vacíos
            const emptyRow = headers.reduce((acc, header) => {
              acc[header] = '';  // Asignamos un valor vacío a cada campo
              return acc;
            }, {});

            // Añadimos la fila vacía de totales al final de los datos
            datos.push(emptyRow);

          }

          // Agregamos cabeceras (fíjate que ExcelJS utiliza el método `addRow` para agregar filas)
          const headers = Object.keys(datos[0]).filter(header => header !== 'OBSERVACIONES');
          worksheet.addRow(headers);

          // Agregamos el contenido (filas de datos)
          datos.forEach((row, index) => {
            let rowData = headers.map(header => row[header]);
            worksheet.addRow(rowData);
          });

          //Configuramos los estilos de las celdas
          worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
            row.eachCell({ includeEmpty: true }, function (cell, colIndex) {
              // Estilos generales para todas las celdas
              cell.font = { name: 'Aptos Narrow', size: 9 };
              cell.alignment = { vertical: 'middle', horizontal: 'left' };
              cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };

              // Agregar comentarios (Notas) en la columna 11 (Índice 11, que corresponde a la columna "OBSERVACIONES")


              // Agregar bordes
              if (rowIndex === 1) {
                // Cabecera
                cell.border = {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' },
                };

                cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };
                cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };

              } else {
                // if (colIndex === 11) {
                //   let comentario = (datos[rowIndex] && datos[rowIndex]['OBSERVACIONES']) ? datos[rowIndex]['OBSERVACIONES'].toString() : '';
                //   cell.note = comentario;  // Asignar el comentario a la celda
                //   // cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };
                //   // cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };
                // }
                // Bordes de celdas normales
                cell.border = {
                  top: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' },
                  right: { style: 'thin' },
                };
              }


              if (rowTotaly) {

                if (rowIndex == totaly) {
                  cell.font = { name: 'Aptos Narrow', size: 10, bold: true, color: { argb: 'FFFFFF' } };
                  cell.alignment = { vertical: 'middle', horizontal: 'center' };
                  cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '3475b1' } };

                }

              }

            });
          });

          if (e.sizeColumns && e.sizeColumns.length > 0) {

            worksheet.columns = headers.map((header, index) => {

              const columnConfig = e.sizeColumns.find(col => col.index === index + 1)

              return { key: header, width: columnConfig ? columnConfig.size : 13 }
            })

          }


          // TOTALES

          if (rowTotaly) {

            if (e.totalY.columns.length > 0) {

              e.totalY.columns.forEach(r => {

                // Esta funcion va de 1 a mas
                const columna = this.getExcelColumn(r);
                const celda = columna + totaly
                const desde = columna + datax
                const hasta = columna + (totaly - 1)

                worksheet.getCell(celda).value = { formula: 'SUM(' + desde + ':' + hasta + ')', result: 100 };

              })

            }

          }




        }

      })
      if (options.typeFile === '.csv') {
        workbook.csv.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = options.fileName + options.typeFile;
          link.click();
        }).catch(err => {
          console.error('Error al generar el archivo Excel:', err);
        });
      } else if (options.typeFile === '.xlsx') {
        workbook.xlsx.writeBuffer().then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = options.fileName + options.typeFile;
          link.click();
        }).catch(err => {
          console.error('Error al generar el archivo Excel:', err);
        });
      }




    }




  }

  getExcelColumn(index: number): string {
    let columnName = "";
    index -= 1;
    while (index >= 0) {
      columnName = String.fromCharCode(index % 26 + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  }

  listaLTW: any[] = [];
  tamano: number = 0;
  db: IDBDatabase | null = null;
  registrosRecuperados: any[] = [];

  async loadParameters(): Promise<void> {
    return new Promise<void>((resolve, reject) => {  // Aseguramos que logearse devuelve una promesa
      // Verificamos si estamos en el navegador antes de acceder a IndexedDB
      if (typeof indexedDB !== 'undefined') {
        const request = indexedDB.open('DelfinGroup', 1); // Abrimos la base de datos 'DelfinGroup'

        request.onupgradeneeded = (event) => {
          this.db = (event.target as IDBRequest).result;
          if (this.db && !this.db.objectStoreNames.contains('registros')) {
            this.db.createObjectStore('registros', { keyPath: 'id' });  // Creamos un object store si no existe
          }
        };

        request.onsuccess = async (event) => {
          this.db = (event.target as IDBRequest).result;  // Guardamos la referencia de la DB
          console.log('Base de datos abierta con éxito');
          try {
            // Esperamos a que almacenarDatos se complete antes de continuar
            await this.almacenarDatos();
            console.log('Todos los registros han sido almacenados en IndexedDB');
            resolve();  // Cuando todo se complete, resolvemos la promesa
          } catch (error) {
            console.error('Error al almacenar los datos:', error);
            reject(error);  // Si ocurre un error, rechazamos la promesa
          }
        };

        request.onerror = (event) => {
          console.error('Error al abrir la base de datos:', event);
          reject('Error al abrir la base de datos');
        };
      } else {
        console.error('indexedDB no está disponible en este entorno');
        reject('indexedDB no está disponible');  // Rechazamos si no está disponible
      }
    });
  }

  async logearse(): Promise<void> {
    return new Promise<void>((resolve, reject) => {  // Aseguramos que logearse devuelve una promesa
      // Verificamos si estamos en el navegador antes de acceder a IndexedDB
      if (typeof indexedDB !== 'undefined') {
        const request = indexedDB.open('DelfinGroup', 1); // Abrimos la base de datos 'DelfinGroup'

        request.onupgradeneeded = (event) => {
          this.db = (event.target as IDBRequest).result;
          if (this.db && !this.db.objectStoreNames.contains('registros')) {
            this.db.createObjectStore('registros', { keyPath: 'id' });  // Creamos un object store si no existe
          }
        };

        request.onsuccess = async (event) => {
          this.db = (event.target as IDBRequest).result;  // Guardamos la referencia de la DB
          console.log('Base de datos abierta con éxito');
          try {
            // Esperamos a que almacenarDatos se complete antes de continuar
            await this.almacenarDatos();
            // console.log('Todos los registros han sido almacenados en IndexedDB');
            resolve();  // Cuando todo se complete, resolvemos la promesa
          } catch (error) {
            console.error('Error al almacenar los datos:', error);
            reject(error);  // Si ocurre un error, rechazamos la promesa
          }
        };

        request.onerror = (event) => {
          console.error('Error al abrir la base de datos:', event);
          reject('Error al abrir la base de datos');
        };
      } else {
        console.error('indexedDB no está disponible en este entorno');
        reject('indexedDB no está disponible');  // Rechazamos si no está disponible
      }
    });
  }

  async almacenarDatos(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {  // Especificamos que la promesa no retorna nada, es de tipo void
      if (this.db) {
        try {
          // this.listaLTW = await this.loadParametros_v2();  // Usamos await correctamente

          let parameters = await this.loadParametros_v2();

          parameters.map((r, index) => {
            const item: any = {
              id: 1 + index,
              tipo: r.tipo,
              datos: this.crypto.encrypt(JSON.stringify(
                {
                  codigo: r.codigo,
                  nombre: r.nombre,
                  via: r.via,
                  regimen: r.regimen,
                  lineaNegocio: r.lineaNegocio,
                  adicional1: r.adicional1,
                  adicional2: r.adicional2,
                  adicional3: r.adicional3,
                  pais: r.pais,
                  codpais: r.codpais
                }

              ), 'Lim@2023')
              // codigo:r.codigo,
              // nombre:r.nombre,
              // via:r.via,
              // regimen:r.regimen,
              // lineaNegocio:r.lineaNegocio,
              // adicional1:r.adicional1,
              // adicional2:r.adicional2,
              // adicional3:r.adicional3,
              // pais:r.pais,
              // codpais:r.codpais              
            }
            this.listaLTW.push(item)
          })

          // console.log(data)
          // this.listaLTW.forEach((registro, index) => {
          //   registro.id = index + 1; // Asigna el 'id' incrementando desde 1
          // });
          // console.log(this.listaLTW)



          // Creamos una transacción para almacenar los datos
          const transaction = this.db.transaction('registros', 'readwrite');
          const store = transaction.objectStore('registros');

          this.listaLTW.forEach((registro) => {
            store.put(registro); // Almacenamos cada objeto en el object store
          });

          transaction.oncomplete = () => {
            console.log('Todos los registros han sido almacenados en IndexedDB');
            resolve(); // Resolvemos la promesa sin devolver valor (void)
          };

          transaction.onerror = (event) => {
            console.error('Error al almacenar los datos en IndexedDB', event);
            reject(event); // Rechazamos la promesa en caso de error
          };
        } catch (error) {
          console.error('Error al cargar parámetros', error);
          reject(error);  // Si algo falla al cargar los parámetros, rechazamos la promesa
        }
      } else {
        console.error('Base de datos no disponible');
        reject('Base de datos no disponible'); // Rechazamos si no hay acceso a la base de datos
      }
    });
  }


  obtenerDatos(tipo: any[]) {
    console.log('entro a obtener datos')
    return new Promise((resolve, reject) => {
      if (typeof indexedDB !== 'undefined') {
        const request = indexedDB.open('DelfinGroup', 1); // Abrimos la base de datos 'DelfinGroup'

        request.onupgradeneeded = (event) => {
          this.db = (event.target as IDBRequest).result;
          if (this.db && !this.db.objectStoreNames.contains('registros')) {
            this.db.createObjectStore('registros', { keyPath: 'id' }); // Creamos un object store si no existe
          }
        };

        request.onsuccess = (event) => {
          this.db = (event.target as IDBRequest).result; // Obtener la base de datos cuando la apertura es exitosa
          const transaction = this.db.transaction('registros', 'readonly'); // Iniciar la transacción
          const store = transaction.objectStore('registros'); // Obtener el object store
          const requestGetAll = store.getAll(); // Obtener todos los registros          
          requestGetAll.onsuccess = () => {

            this.registrosRecuperados = requestGetAll.result; // Guardamos los registros recuperados
            const parametros = []

            if (tipo[0].tipo === 'TODOS') {
              this.registrosRecuperados.map(x => {
                let datosDescifrados = JSON.parse(this.crypto.decrypt(x.datos, 'Lim@2023'));
                datosDescifrados.id = x.id;
                datosDescifrados.tipo = x.tipo;
                parametros.push(datosDescifrados);
              })
              resolve(parametros);
            } else {
              let listaFinal: any[] = []
              tipo.forEach(element => {
                let parametrofiltrado
                parametrofiltrado = this.registrosRecuperados.filter(x => {
                  return x.tipo === element.tipo
                })
                listaFinal.push(parametrofiltrado)
                console.log(listaFinal)
              });
              // Combina todas las listas dentro de listaFinal en una sola lista
              const listaUnificada = [].concat(...listaFinal);
              console.log(listaUnificada);

              listaUnificada.map(x => {
                let datosDescifrados = JSON.parse(this.crypto.decrypt(x.datos, 'Lim@2023'));
                datosDescifrados.id = x.id;
                datosDescifrados.tipo = x.tipo;
                parametros.push(datosDescifrados);
              })
              resolve(parametros);
            }


            // console.log(parametros)
            // if(tipo==='TODOS'){
            //   resolve(parametros);
            // }else{
            //   let parametrofiltrado
            //   parametrofiltrado=parametros.filter(x=>{
            //     return x.tipo===tipo
            //   })
            //   resolve(parametrofiltrado);
            // }
            // Resolvemos la promesa con los datos
          };

          requestGetAll.onerror = (event) => {
            reject('Error al recuperar los datos desde IndexedDB'); // Rechazamos la promesa si ocurre un error
          };
        };

        request.onerror = (event) => {
          reject('Error al abrir la base de datos'); // Rechazamos la promesa si ocurre un error
        };
      } else {
        reject('IndexedDB no está soportado en este navegador'); // Si IndexedDB no está disponible
      }
    });
  }


  eliminarBaseDeDatos() {
    if (typeof indexedDB !== 'undefined') {
      const request = indexedDB.deleteDatabase('DelfinGroup'); // Nombre de la base de datos a eliminar

      request.onsuccess = () => {
        console.log('Base de datos eliminada con éxito');
      };

      request.onerror = (event) => {
        console.error('Error al eliminar la base de datos', event);
      };

      request.onblocked = () => {
        console.log('La base de datos está bloqueada y no se puede eliminar en este momento');
      };
    } else {
      console.error('indexedDB no está disponible en este entorno');
    }
  }


}

export interface DataExportExcel {
  ColeccionExcel: ItemsDataExportExcel
}

export interface ItemsDataExportExcel {
  data: any[]
  cabeceras: string[]
}

export interface dropdownItems {
  label: string
  value: any
}

export interface optionExcelExport {
  fileName: string
  sheets: optionsSheetExcel[]
  typeFile: '.csv' | '.xlsx';
}


export class optionsSheetExcel {
  data: any[]
  sheetName: string
  coordinates?: coordinatesExcel = { x: 1, y: 1 }
  totalY?: totalExcel = { status: false, columns: [] }
  totalX?: totalExcel = { status: false, columns: [] }
  sizeColumns?: ColumnExcelSize[]
}

export interface coordinatesExcel {
  x: number
  y: number
}

export interface totalExcel {
  status: boolean
  columns?: number[]
}

export interface addContentExcel {
  celda: string
  data: any
}

export interface ColumnExcelSize {
  index: number
  size?: number

}
