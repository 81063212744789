import { Injectable } from '@angular/core';
import { PricingRepository } from '../repositorio/pricing.repository';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GeneralResponse } from 'src/app/comercial/reffer/domain/reffer-response';
import { Obtener_DplaMax_PlantillaRequest, TAR_ListarPlantillasRequest, TAR_MantenimientoPlantillasRequest } from '../componentes/plantilla/domain/plantilla.request';
import { Obtener_DplaMax_PlantillaReponse, TAR_ListarPlantillasResponse } from '../componentes/plantilla/domain/plantilla.response';
import { ListarTarifaRequest, ListaTarifaRequest, MantenimientoTarifaRequest } from '../componentes/tarifa/domain/tarifa.request';
import { ListarTarifaResponse, ListaTarifaResponse } from '../componentes/tarifa/domain/tarifa.response';
import { GeneralTransaccionResponse } from 'src/app/operacion/tarja/domain/tarja.response';
import { ActualizarEstContratoRequest, ActualizarTarifarioRequest, ListarContratoyTarifaRequest, ListarTariProfitPromRequest, ListarTariSegmentadaRequest, SegmentarTarifaRequest, TransaccionProfitPromRequest, ValidarContratoRequest } from '../componentes/segmentacion-de-tarifa/domain/segmentacion-de-tarifa.request';
import { ActualizarEstContratoResponse, ActualizarTarifarioResponse, ListarContratoyTarifaDetalleResponse, ListarContratoyTarifaResponse, ListarTariProfitPromResponse, ListarTariSegmentadaResponse, SegmentarTarifaResponse, TransaccionProfitPromResponse, ValidarContratoResponse } from '../componentes/segmentacion-de-tarifa/domain/segmentacion-de-tarifa.response';

@Injectable({
  providedIn: 'root'
})
export class PricingService extends PricingRepository { 

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }
  //#region PLANTILLLA
  TAR_ListarPlantillas(fd: TAR_ListarPlantillasRequest): Observable<TAR_ListarPlantillasResponse> {
    return this.http.post<TAR_ListarPlantillasResponse>(`${environment.PATH_API}/pricing/TAR_ListarPlantillas`, fd);
  }
  TAR_MantenimientoPlantillas(fd: TAR_MantenimientoPlantillasRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/pricing/TAR_MantenimientoPlantillas`, fd);
  }
  Obtener_DplaMax_Plantilla(fd: Obtener_DplaMax_PlantillaRequest): Observable<Obtener_DplaMax_PlantillaReponse> {
    return this.http.post<Obtener_DplaMax_PlantillaReponse>(`${environment.PATH_API}/pricing/Obtener_DplaMax_Plantilla`, fd);
  }
  //#endregion 

  override ListaTarifa(request: ListaTarifaRequest): Observable<ListaTarifaResponse> {
    return this.http.post<ListaTarifaResponse>(`${environment.PATH_API}/supply/ListaTarifa`, request)
  }
 
  override MantenimientoTarifa(request: MantenimientoTarifaRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/supply/ManteTari`, request)
  }

  override ListaContratoyTarifa(request: ListarContratoyTarifaRequest): Observable<ListarContratoyTarifaResponse> {
    return this.http.post<ListarContratoyTarifaResponse>(`${environment.PATH_API}/segmentaciontarifas/ListarContratosyTarifas`, request)
  }

  override ListaContratoyTarifaDetalle(request: ListarContratoyTarifaRequest): Observable<ListarContratoyTarifaDetalleResponse> {
    return this.http.post<ListarContratoyTarifaDetalleResponse>(`${environment.PATH_API}/segmentaciontarifas/ListarContratosyTarifas`, request)
  }
  override TransPofitProm(request: TransaccionProfitPromRequest): Observable<TransaccionProfitPromResponse> {
    return this.http.post<TransaccionProfitPromResponse>(`${environment.PATH_API}/segmentaciontarifas/TransaccionProfitProm`, request)
  }
  override ListarTariProfitProm(request: ListarTariProfitPromRequest): Observable<ListarTariProfitPromResponse> {
    return this.http.post<ListarTariProfitPromResponse>(`${environment.PATH_API}/segmentaciontarifas/ListarTarifarioProfitProm`, request)
  }
  override SegmentarTarifa(request: SegmentarTarifaRequest): Observable<SegmentarTarifaResponse> {
    return this.http.post<SegmentarTarifaResponse>(`${environment.PATH_API}/segmentaciontarifas/SegmentacionTarifa`, request)
  }
  override ListarTarifaSeg(request: ListarTariSegmentadaRequest): Observable<ListarTariSegmentadaResponse> {
    return this.http.post<ListarTariSegmentadaResponse>(`${environment.PATH_API}/segmentaciontarifas/ListarTarifaSegmentada`, request)
  }
  override ValidarContrato(request: ValidarContratoRequest): Observable<ValidarContratoResponse> {
    return this.http.post<ValidarContratoResponse>(`${environment.PATH_API}/segmentaciontarifas/ValidarContrato`, request)
  }
  override ActualizarTarifario(request: ActualizarTarifarioRequest): Observable<ActualizarTarifarioResponse> {
    return this.http.post<ActualizarTarifarioResponse>(`${environment.PATH_API}/segmentaciontarifas/ActtualizarTarifario`, request)
  }
  override ActualizarEstContrato(request: ActualizarEstContratoRequest): Observable<ActualizarEstContratoResponse> {
    return this.http.post<ActualizarEstContratoResponse>(`${environment.PATH_API}/segmentaciontarifas/ActtualizarEstadoContrato`, request)
  }
}
