import { Observable } from "rxjs";
import { ListarClienteCorreoMasivoRequest, ListarCorreosEncuestaRequest, ObtenerArchivoCorreoRequest, TransaccionCorreoEncuestaRequest } from "./componentes/correo-masivo/domain/request/customer-request";
import { ListarClienteCorreoMasivoResponse, ListarCorreosEncuestaResponse, ObtenerArchivoCorreoResponse, TransaccionCorreoEncuestaResponse } from "./componentes/correo-masivo/domain/response/customer-response";
import { paInsertaOVLoadingListRequest } from "../loading-list/domain/request/loading-list-request";
import { paInsertaOVLoadingListResponse } from "../loading-list/domain/response/loading-list-response";

export abstract class CustomerRepository{
    abstract ListarCorreosEncuesta(fd: ListarCorreosEncuestaRequest): Observable<ListarCorreosEncuestaResponse>;
    abstract TransaccionCorreoEncuesta(fd: TransaccionCorreoEncuestaRequest): Observable<TransaccionCorreoEncuestaResponse>;
    abstract ListarCliCorreoMasivo(fd: ListarClienteCorreoMasivoRequest): Observable<ListarClienteCorreoMasivoResponse>;
    abstract ObtenerArchivoCorreo(fd: ObtenerArchivoCorreoRequest): Observable<ObtenerArchivoCorreoResponse>;
    abstract paInsertaOV_LoadingList(fd:paInsertaOVLoadingListRequest): Observable<paInsertaOVLoadingListResponse>;
}