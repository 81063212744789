import { Observable } from "rxjs";
import { GeneralResponse } from "src/app/comercial/reffer/domain/reffer-response";
import { Obtener_DplaMax_PlantillaRequest, TAR_ListarPlantillasRequest, TAR_MantenimientoPlantillasRequest } from "../componentes/plantilla/domain/plantilla.request";
import { Obtener_DplaMax_PlantillaReponse, TAR_ListarPlantillasResponse } from "../componentes/plantilla/domain/plantilla.response";
import { ListarTarifaRequest, ListaTarifaRequest, MantenimientoTarifaRequest } from "../componentes/tarifa/domain/tarifa.request";
import { ListarTarifaResponse, ListaTarifaResponse } from "../componentes/tarifa/domain/tarifa.response";
import { GeneralTransaccionResponse } from "src/app/operacion/tarja/domain/tarja.response";
import { ActualizarEstContratoRequest, ActualizarTarifarioRequest, ListarContratoyTarifaRequest, ListarTariProfitPromRequest, ListarTariSegmentadaRequest, SegmentarTarifaRequest, TransaccionProfitPromRequest, ValidarContratoRequest } from "../componentes/segmentacion-de-tarifa/domain/segmentacion-de-tarifa.request";
import { ActualizarEstContratoResponse, ActualizarTarifarioResponse, ListarContratoyTarifaDetalleResponse, ListarContratoyTarifaResponse, ListarTariProfitPromResponse, ListarTariSegmentadaResponse, SegmentarTarifaResponse, TransaccionProfitPromResponse, ValidarContratoResponse } from "../componentes/segmentacion-de-tarifa/domain/segmentacion-de-tarifa.response";

export abstract class PricingRepository {
    //#region PLANTILLA
    abstract TAR_ListarPlantillas(fd: TAR_ListarPlantillasRequest): Observable<TAR_ListarPlantillasResponse>;
    abstract TAR_MantenimientoPlantillas(fd: TAR_MantenimientoPlantillasRequest): Observable<GeneralResponse>
    abstract Obtener_DplaMax_Plantilla(fd:Obtener_DplaMax_PlantillaRequest):Observable<Obtener_DplaMax_PlantillaReponse>
    //#END 
    // abstract ListarTarifa(request: ListarTarifaRequest): Observable<ListarTarifaResponse>
    abstract MantenimientoTarifa(request: MantenimientoTarifaRequest): Observable<GeneralTransaccionResponse>
    abstract ListaTarifa(request: ListaTarifaRequest): Observable<ListaTarifaResponse>
    abstract ListaContratoyTarifa(request: ListarContratoyTarifaRequest): Observable<ListarContratoyTarifaResponse>
    abstract ListaContratoyTarifaDetalle(request: ListarContratoyTarifaRequest): Observable<ListarContratoyTarifaDetalleResponse>
    abstract TransPofitProm(request: TransaccionProfitPromRequest): Observable<TransaccionProfitPromResponse>
    abstract ListarTariProfitProm(request: ListarTariProfitPromRequest): Observable<ListarTariProfitPromResponse>
    abstract SegmentarTarifa(request: SegmentarTarifaRequest): Observable<SegmentarTarifaResponse>
    abstract ListarTarifaSeg(request: ListarTariSegmentadaRequest): Observable<ListarTariSegmentadaResponse>
    abstract ValidarContrato(request: ValidarContratoRequest): Observable<ValidarContratoResponse>
    abstract ActualizarTarifario(request: ActualizarTarifarioRequest): Observable<ActualizarTarifarioResponse>
    abstract ActualizarEstContrato(request: ActualizarEstContratoRequest): Observable<ActualizarEstContratoResponse>
}