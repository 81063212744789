import { ProformaService } from './../../../../services/proforma.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, } from '@angular/forms';
import { UserEntity } from 'src/app/usuario/domain/user-entity';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { CambiaContrasenaComponent } from '../cambiaContrasena/cambiaContrasena.component';
import { requestExisteUsuario } from 'src/app/proforma/domain/request/proforma_request';
import { ProformaRepository } from 'src/app/proforma/domain/proforma.repository';
import { PageLoginComponent } from '../../page-login/page-login.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  
  //Captcha
  nombreUsuario: string;
  protected aFormGroup: FormGroup;
  //Modificado
  hide = true;
  color: string = 'red';
  loginloading:boolean=false
  @Output() outputLogin: EventEmitter<UserEntity> = new EventEmitter<UserEntity>();
  // @ViewChild(PageLoginComponent) pageLoginComponent: PageLoginComponent; 
  group: FormGroup;

  usuario: "";

  constructor(private router: Router, private util: UtilService, private readonly proformaService: ProformaRepository) { }

  // ngAfterViewInit(): void {
  //   // Una vez que la vista está completamente inicializada, accedes al valor de 'Estado'
  //   console.log('Estado desde el componente hijo:', this.pageLoginComponent.Estado);
  //   this.loginloading=false
  // }


  ngOnInit(): void {
    this.initializeForm();
  }

  //siteKey:string = "6Ld1fEYkAAAAACmZpBYQtpgqldykOgN7m9qU2aku";

  registrar() {
    this.router.navigate(['BandejaItinerarios'])
  }
  initializeForm() {
    this.group = new FormGroup({
      usuario: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  login() {
    //esto no subio
    // this.loginloading=true
    const day = new Date();
    const targetDate = new Date('2024-11-19');
    const dateNext = new Date('2024-11-20')

    console.log(day)
    console.log(targetDate)
    if ((day.getFullYear() === targetDate.getFullYear() &&
      day.getMonth() === targetDate.getMonth() &&
      day.getDate() === targetDate.getDate()) || (day.getFullYear() === dateNext.getFullYear() &&
        day.getMonth() === dateNext.getMonth() &&
        day.getDate() === dateNext.getDate())) {
      if (this.group.value.password == '12345') {
        this.OpenModal('', this.group.value.usuario)
        return
      }
    }

    const user: UserEntity = this.group.value;
    this.outputLogin.emit(this.group.value);
    // console.log(this.group);
    // console.log(user);
    // this.loginloading=false

    // PageLoginComponent.
  }

  handleKeyDown(event: any) {
    if (event.keyCode == 13) {
      // action
      this.login();
    }
    else if (event.keyCode == 40) {
      // action
    }
    else if (event.keyCode == 38) {
      // action
    }
  }

  capturar(value) {

    //this.usuario = evt.target.value;
    console.log(value.target.value)
  }

  validarUser() {

  }

  OpenModal(value, user) {
    const options = {
      disableClose: true,
      panelClass: 'panel',
      data: user
    };

    const reference = this.util.openModal(
      CambiaContrasenaComponent,
      options,
    );
  }

  ingresaUsuario() {
    const value = this.group.value;
    this.nombreUsuario = value.usuario;
  }

  Apaagar(){
    this.loginloading=false
  }
}
