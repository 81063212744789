import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginUserCU } from 'src/app/usuario/application/user-logincu';
import { UserEntity } from 'src/app/usuario/domain/user-entity';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.css']
})

export class PageLoginComponent implements OnInit {
  
  // @Output() outputrptalogin: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private userlogincu: LoginUserCU) { }

  ngOnInit(): void {
  }

  // public Estado:boolean = false
   async setCredentials(user: UserEntity){
    //console.log(value);
    //Enviar a la capa de aplicacion 
    //tiene que llamar al controlador userlogincaseuse
    let data=await this.userlogincu.login(user);
    console.log(data)
    // this.Estado=true
    // if(data!=null){
    //   // this.outputrptalogin.emit(true);
      
    // }
    
  }

}
