import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ResponseLogin, UserEntity } from "../domain/user-entity";
import { UserRepository } from "../domain/user-repository";
import { UserOperations } from "../infraestructura/user-operation";

//para que se instacie una sola vez ahy que indicarle que es un servicio
@Injectable({
providedIn: 'root', //para que se puedaver desde cualquier componente

})
export class LoginUserCU{

  constructor(private readonly auth: AuthService, 
              private readonly userRepository: UserRepository
  ){}

    // login(user: UserEntity): ResponseLogin {//Backup
    //   return this.auth.login(user);
    //  };
    async login(user: UserEntity): Promise<ResponseLogin> {
      return this.auth.login(user);  // Llamas a la función que devuelve una promesa
    }
    
     logout(){
      this.auth.logout();

     }

}