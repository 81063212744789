import { Observable } from "rxjs";
import { RequestAnularCobranza, RequestImportarCobranzas, RequestListarCobranzas, RequestTransaccionCobranza, RequestdashboardCobranza } from "./request/request_cobranzasdiarias";
import { ResponseAnularCobranza, ResponseImportarCobranzasDiarias, ResponseListadoCobranzasDiarias, ResponseListadoReporteEjecutivo, ResponseListadoReporteResumen, ResponseTransaccionCobranzasDiarias, ResponsedashboardCobranza, ResponsedashboardCobranzaEstado, ResponsedashboardCobranzacliente, ResponsedashboardMontoFecha } from "./response/response_cobranzasdiarias";
import { proformaRequest } from "src/app/proforma/domain/request/proforma_request";
import { ProformaResponse } from "src/app/proforma/domain/response/proforma_response";
import { enviaCorreoAnulacionesRequest, ListarSolicitudAnulacionRequest, TransaccionComprobanteAnulRequest } from "../../solicitud-anulacion/domain/request/solicitud-anulacion-request";
import { ListarSolicitudAnulacionResponse, TransaccionComprobanteAnulResponse } from "../../solicitud-anulacion/domain/response/solicitud-anulacion-response";
import { ReporteCompAnuladasRequest } from "../../reportes/domain/request/reportes-finanzas-request";
import { ReporteCompAnuladasDNResponse, ReporteCompAnuladasResponse } from "../../reportes/domain/response/reportes-finanzas-response";
import { request_facturaventa } from "./request/request_facturaventa";
import { Responselistarfacturaventa } from "./response/response_facturaventa";


export abstract class CobranzasRepository {


    abstract listarCobranzasDiarias(request:RequestListarCobranzas): Observable<ResponseListadoCobranzasDiarias> 

    abstract importarCobranzasDiarias(request:RequestImportarCobranzas): Observable<ResponseImportarCobranzasDiarias> 


    abstract listardashboardcobranzas(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranza> 
  
    abstract listardashboardcobranzascliente(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzacliente> 
    
    abstract listadashboardcobranzasestado(request:RequestdashboardCobranza): Observable<ResponsedashboardCobranzaEstado> 
   
    abstract listar(prolrequest: proformaRequest): Observable<ProformaResponse> 

    abstract  transaccioncobranzas(request:RequestTransaccionCobranza): Observable<ResponseTransaccionCobranzasDiarias> 

    abstract listadoDashboardMontoPorFecha (request:RequestdashboardCobranza): Observable<ResponsedashboardMontoFecha> 
   
    abstract anularCobranza (request:RequestAnularCobranza): Observable<ResponseAnularCobranza> 

    abstract listarReporteEjecutivo (request:RequestListarCobranzas): Observable<ResponseListadoReporteEjecutivo> 

    abstract listarReporteResumen (request:RequestListarCobranzas): Observable<ResponseListadoReporteResumen> 
    abstract listarSolicitudAnulacion(prolrequest: ListarSolicitudAnulacionRequest): Observable<ListarSolicitudAnulacionResponse>
    abstract transaccionComprobanteAnul(prolrequest: TransaccionComprobanteAnulRequest): Observable<TransaccionComprobanteAnulResponse>
    abstract reporteCompAnuladas(prolrequest: ReporteCompAnuladasRequest): Observable<ReporteCompAnuladasResponse>
    abstract enviaCorreoAnulaciones(fd:enviaCorreoAnulacionesRequest);
    abstract reporteCompAnuladasDN(prolrequest: ReporteCompAnuladasRequest): Observable<ReporteCompAnuladasDNResponse>
    abstract consultafacturaventa(prolrequest: request_facturaventa): Observable<Responselistarfacturaventa> 

}
