import { Observable } from "rxjs/internal/Observable";
import { importRebateRequest, listarRebateRequest } from "../rebate/domain/rebate.request";
import { generalTransaccionResponse } from "src/app/shared/domain/shared.request";
import { listarRebateResponse } from "../rebate/domain/rebate.response";

export abstract class ReeferRepository {

    abstract importRebate(request: importRebateRequest): Observable<generalTransaccionResponse>
    abstract listarRebate(request: listarRebateRequest): Observable<listarRebateResponse>

}
