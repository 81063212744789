<mat-toolbar fxLayout fxLayoutAlign="space-between left" class="toolbar" role="banner"
  style="position:sticky;z-index: 99;">

  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <button mat-mini-fab class="Menu" (click)="start.toggle()" aria-label="Example icon button with a menu icon"
      matTooltip="Menu">
      <mat-icon>menu</mat-icon>
    </button>
    <img width="150px" height="100%" src="./assets/Img/logodelfinapp.png">
  </div>

  <!-- This fills the remaining space of the current row -->

  <div class="midivthema">
    <mat-form-field appearance="outline" class="midiv" style="border-color: #ffff;">
      <mat-select [(value)]="selected1" color='primary' class="midiv" style="color: white;">
        <mat-option value="light-theme" (click)="onSetTheme('light-theme')" style="color: white;">Light</mat-option>
        <mat-option value="dark-theme" (click)="onSetTheme('dark-theme')" style="color: white;">Dark</mat-option>
        <mat-option value="vivo-theme" (click)="onSetTheme('vivo-theme')" style="color: white;">Vivo</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- BOTONES-->
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="demo-section">
    <div class="demo-section">
      <mat-icon matBadge="{{n}}" matBadgeColor="warn" (click)="toggleDiv()" (click)="cargarnotificaciones()"
        cdkOverlayOrigin class="noti">notifications</mat-icon>

      <!-- <div #miDiv *ngIf="mostrarDiv">
      Contenido del div que deseas ocultar al hacer clic fuera de él.
    </div> -->


      <div *ngIf="isOpen" tabindex="-1"
        class="mostrarnotificaciones mat-mdc-menu-panel mdc-menu-surface mdc-menu-surface--open mat-mdc-elevation-specific  ng-tns-c3216136785-7 topbar-dd cardWithShadow mat-menu-before mat-menu-below mat-elevation-z8 ng-star-inserted"
        #miDivididor>
        <div class="mat-mdc-menu-content mdc-list ng-tns-c3216136785-7 tablacontent">
          <table id="tablaDatos" style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr>
                <th colspan="3" style="height: 50px;text-transform: uppercase;"> notificaciones </th>
              </tr>

            </thead>
            <tbody style="border: none;">
              <!-- <tr>
                <th style="height: 30px;text-transform: uppercase;font-size: 14px;padding: 10px;"> cotizacion </th>
                <th style="height: 30px;text-transform: uppercase;font-size: 14px;padding: 10px;"> usuario </th>
                <th style="height: 30px;text-transform: uppercase;font-size: 14px;padding: 10px;"> f. creacion </th>
              </tr> -->
              <tr>
                <th style="height: 30px;text-transform: uppercase;font-size: 14px;padding: 10px;"
                  *ngFor="let item of CabecerasNotificaciones"> {{item}} </th>
              </tr>
              <ng-container *ngFor="let dato of listRequisiciones">
                <tr class="header seleccionable" style="cursor: pointer;" (click)="buscarstatement(dato)">
                  <td class="header" style="height: 50px;padding: 10px;text-align: center;">{{dato.creq_nroreq}}</td>
                  <td class="header" style="height: 50px;padding: 10px;text-align: center;">{{dato.audi_usrcrea}}</td>
                  <td class="header" style="height: 50px;padding: 10px;text-align: center;">{{dato.fechanueva}}</td>
                </tr>
              </ng-container>
              <tr class="header exit" style="cursor: pointer;" (click)="toggleDiv()">
                <td colspan="3" class="header" style="height: 50px;padding: 10px;text-align: center;">CERRAR</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" class="midiv">
    <hr class="hr">
    <div fxLayout="row" fxLayoutAlign="start center" class="title" fxLayoutGap="50px">
      Empresa: {{nombreempresa}}
    </div>
    <hr class="hr">
    <div fxLayout="row" fxLayoutAlign="start center" class="title" fxLayoutGap="50px">

      Usuario: {{nombreusuario}}
    </div>
    <hr class="hr">
    <div fxLayout="row" fxLayoutAlign="start center" class="title" fxLayoutGap="50px">
      Version: 5.9.1
    </div>
    <hr class="hr">
    <div>
      <button class="title" mat-button (click)=logout() matTooltip="Cerrar Session">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </div>

  <!-- <div>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="mat-18 iconoCara">face</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <h2 mat-menu-item [ariaReadOnly]="true">
          {{nombreusuario}}
        </h2>
        <button mat-menu-item (click) = logout()>
          <span>Cerrar Sesión</span>
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </mat-menu>
    </div> -->
</mat-toolbar>



<mat-sidenav-container class="sidenav-container">

  <mat-sidenav style="background-color: aqua;" class="mat-elevation-z10" #start [opened]="open" fixedTopGap="64"
    fixedInViewport="true" style="width:300px;background-color:var(--color-menu);">
    <div class="container">
      <img src="{{imagen}}" class="icon-image">
      <span class="nombreUsuario"> {{nombreusuario}}<br>{{descripcionrol}}</span>

      <mat-icon class="mat-18" (click)="logout()" matTooltip="Cerrar Session">exit_to_app</mat-icon>


      <!--<mat-icon class="mat-18"  (click)="logout()">exit_to_app</mat-icon>-->
    </div>
    <app-menu (toggleSidenavEvent)="toggleSidenav()"></app-menu>
  </mat-sidenav>

  <mat-sidenav-content style="margin-top:0px;">
    <div style="margin: 10px;">
      <router-outlet></router-outlet>
    </div>


  </mat-sidenav-content>

</mat-sidenav-container>