import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CustomerRepository } from './customer.repository';
import { ListarClienteCorreoMasivoRequest, ListarCorreosEncuestaRequest, ObtenerArchivoCorreoRequest, TransaccionCorreoEncuestaRequest } from './componentes/correo-masivo/domain/request/customer-request';
import { ListarClienteCorreoMasivoResponse, ListarCorreosEncuestaResponse, ObtenerArchivoCorreoResponse, TransaccionCorreoEncuestaResponse } from './componentes/correo-masivo/domain/response/customer-response';
import { paInsertaOVLoadingListRequest } from '../loading-list/domain/request/loading-list-request';
import { paInsertaOVLoadingListResponse } from '../loading-list/domain/response/loading-list-response';

@Injectable({
  providedIn: 'root'
})
export class CustomerService implements CustomerRepository {

  constructor(private readonly http: HttpClient) { }

  ListarCorreosEncuesta(fd: ListarCorreosEncuestaRequest): Observable<ListarCorreosEncuestaResponse> {
    return this.http.post<ListarCorreosEncuestaResponse>(`${environment.PATH_API}/customercomercial/ListarCorreosEncuestas`, fd);
  }
  TransaccionCorreoEncuesta(fd: TransaccionCorreoEncuestaRequest): Observable<TransaccionCorreoEncuestaResponse> {
    return this.http.post<TransaccionCorreoEncuestaResponse>(`${environment.PATH_API}/customercomercial/TransaccionCorreoEncuesta`, fd);
  }
  ListarCliCorreoMasivo(fd: ListarClienteCorreoMasivoRequest): Observable<ListarClienteCorreoMasivoResponse> {
    return this.http.post<ListarClienteCorreoMasivoResponse>(`${environment.PATH_API}/customercomercial/ListarClienteCorreoMasivo`, fd);
  }
  ObtenerArchivoCorreo(fd: ObtenerArchivoCorreoRequest): Observable<ObtenerArchivoCorreoResponse> {
    return this.http.post<ObtenerArchivoCorreoResponse>(`${environment.PATH_API}/customercomercial/ObtenerArchivosCorreo`, fd);
  }
  paInsertaOV_LoadingList(fd: paInsertaOVLoadingListRequest): Observable<paInsertaOVLoadingListResponse> {
    return this.http.post<paInsertaOVLoadingListResponse>(`${environment.PATH_API}/customercomercial/paInsertaOV_LoadingList`, fd);
  }
}
