import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReporteTarifaRepository } from './reporte-tarifa.repository';
import { PRI_ReporteTarifaRequest } from '../reporte-tarifa-2/domain/reporte-tarifa.request';
import { PRI_ReporteTarifaResponse } from '../reporte-tarifa-2/domain/reporte-tarifa.response';
@Injectable({
  providedIn: 'root'
})
export class ReporteTarifaService extends ReporteTarifaRepository {    
    constructor(
        private readonly http: HttpClient
    ) {
        super();
    }
    override PRI_ReporteTarifa(fd: PRI_ReporteTarifaRequest): Observable<PRI_ReporteTarifaResponse> {
        return this.http.post<PRI_ReporteTarifaResponse>(`${environment.PATH_API}/supply/PRI_ReporteTarifa`, fd);
    }

}