import { Injectable } from '@angular/core';
import { ReeferRepository } from './reefer.repository';
import { Observable } from 'rxjs';
import { generalTransaccionResponse } from 'src/app/shared/domain/shared.request';
import { importRebateRequest, listarRebateRequest } from '../rebate/domain/rebate.request';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { listarRebateResponse } from '../rebate/domain/rebate.response';

@Injectable({
  providedIn: 'root'
})
export class ReeferService extends ReeferRepository {

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }

  override importRebate(request: importRebateRequest): Observable<generalTransaccionResponse> {
    return this.http.post<generalTransaccionResponse>(`${environment.PATH_API}/reffer/ImportarRebateInformacion`, request)
  }

  override listarRebate(request: listarRebateRequest): Observable<listarRebateResponse> {
    return this.http.post<listarRebateResponse>(`${environment.PATH_API}/reffer/ListarRebateInformacion`, request)
  }

}
