import { Observable } from "rxjs";
import { ListarEntPendAprobRequest, TransaccionEstadoVinculacionRequest } from "./componentes/vinculacion-empresas/domain/request/ventas-request";
import { ListarEntPendAprobResponse, TransaccionEstadoVinculacionResponse } from "./componentes/vinculacion-empresas/domain/response/ventas-response";
import { ListarContratoPorLineaRequest, ListarCuotaContratoRequest, ListarCuotaNaveETDRequest, ReporteLineaContratoRequest, TransaccionCuotaContratoRequest, ValidarCuotaContratoRequest } from "./cuota-linea-tipo-contrato/domain/cuota-linea-tipo-contrato.request";
import { ListarContratoPorLineaResponse, ListarCuotaContratoResponse, ListarCuotaNaveETDResponse, ReporteLineaContratoResponse, TransaccionCuotaContratoResponse, ValidarCuotaContratoResponse } from "./cuota-linea-tipo-contrato/domain/cuota-linea-tipo-contrato.response";
import { anularRequerimientoRequest, mantenimientoRequerimientoRequest, rechazarRequerimientoRequest } from "./requerimiento/domain/requerimiento.request";
import { GeneralTransaccionResponse } from "src/app/operacion/tarja/domain/tarja.response";

export abstract class VentasRepository {
    
    abstract ListarEntidadesPendAprob(fd: ListarEntPendAprobRequest): Observable<ListarEntPendAprobResponse>;
    abstract TransaccionEstVinc(fd: TransaccionEstadoVinculacionRequest): Observable<TransaccionEstadoVinculacionResponse>;
    abstract ListarContrato(fd: ListarContratoPorLineaRequest): Observable<ListarContratoPorLineaResponse>;
    abstract ListarCuotaContrato(fd: ListarCuotaContratoRequest): Observable<ListarCuotaContratoResponse>
    abstract TransaccionCuotaContrato(fd: TransaccionCuotaContratoRequest): Observable<TransaccionCuotaContratoResponse>
    abstract ValidarCuotaContrato(fd: ValidarCuotaContratoRequest): Observable<ValidarCuotaContratoResponse>
    //#region REQUERIMIENTO

    abstract mantenimientoRequerimiento(fd: mantenimientoRequerimientoRequest): Observable<GeneralTransaccionResponse>;
    abstract rechazarRequerimiento(rq: rechazarRequerimientoRequest): Observable<GeneralTransaccionResponse>
    abstract anularRequerimiento(rq: anularRequerimientoRequest): Observable<GeneralTransaccionResponse>

    //#endregion
    abstract ReporteLineaContrato(rq: ReporteLineaContratoRequest): Observable<ReporteLineaContratoResponse>
    abstract ListCuotaNaveETD(rq: ListarCuotaNaveETDRequest): Observable<ListarCuotaNaveETDResponse>
}