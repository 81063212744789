
//@ts-ignore
import { ClienteService } from './services/cliente.service';

import { MenuRepository } from '../../src/app/menus/domain/menu.repository';
import { RolRepository } from '../../src/app/roles/domain/rol.repository';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { UserRepository } from './usuario/domain/user-repository';
import { UserOperations } from './usuario/infraestructura/user-operation';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { MatInputModule } from '@angular/material/input';

import { RolService } from './services/rol.service';
import { ManMenuService } from './services/manMenu.service';


import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { GeolocalizacionModule } from './geolocalizacion/geolocalizacion.module';
import { RequerimientomantComponent } from './requerimiento/presentacion/components/requerimientomant/requerimientomant.component';
import { PageRequerimientomantComponent } from './requerimiento/presentacion/page-requerimientomant/page-requerimientomant.component';
import { ProformaRepository } from './proforma/domain/proforma.repository';
import { ProformaService } from './services/proforma.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClienteComponent } from './cliente/presentacion/components/cliente/cliente.component';

import { VisitaclienteComponent } from './visitascliente/presentacion/components/visitacliente/visitacliente.component';
import { ClienteRepository } from './visitascliente/dominio/cliente.repository';
import { GestionvisitasComponent } from './gestionvisitas/presentacion/components/gestionvisitas/gestionvisitas.component';
import { PageGestionvisitasComponent } from './gestionvisitas/presentacion/page-gestionvisitas/page-gestionvisitas.component';
// import { EfectividadVentasComponent } from './efectividad_ventas/presentacion/efectividad-ventas/efectividad-ventas.component';
//import { ReportesComponent } from './reportes/reportes.component';
//import { ProyeccionCuotasComponent } from './reportes/proyeccion-cuotas/proyeccion-cuotas.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AlertaDocumentoRepository } from './bandeja-alerta-documento/domain/alerta_documento_repository';
import { AlertaDocumentoService } from './services/alertaDocumento.service';
import { PreResRepository } from './cotizaciones_resumentes/domain/pre_res_repository';
import { PreResService } from './services/cotizacionResumen.service';
import { DashBoardRepository } from './dashboardVentas/domain/dashboard.repository';
import { DashBoardService } from './services/dashboard.service';
import { ComisionesRepository } from './comisiones/domain/Comisiones-repository';
import { ComisionesService } from './services/comisiones.service';
import { OvVersionarService } from './services/ovVersionar.service';
import { OvVersionarRepository } from './ovPorVersionar/domain/ov-por-versionar.repository';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { BandejaStatemetAvisoComponent } from './bandeja-statement/presentacion/bandeja-statemet-aviso/bandeja-statemet-aviso.component';
import { BandejaReporteGcchinaComponent } from './reporte-gc-china/presentacion/bandeja-reporte-gcchina/bandeja-reporte-gcchina.component';
// import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // Importa el módulo aquí
import { MatExpansionModule } from '@angular/material/expansion';
import { BandejaCtnrAlmacenComponent } from './reporte-ctnr-almacen/presentacion/bandeja-ctnr-almacen/bandeja-ctnr-almacen.component';
import { BandejaClienteContactoComponent } from './reporte-cliente-contacto/presentacion/bandeja-cliente-contacto/bandeja-cliente-contacto.component';
import { BandejaGestionSliComponent } from './dashboard-gestion-sli/presentacion/bandeja-gestion-sli/bandeja-gestion-sli.component';
import { BandejaReporteAcosComponent } from './reporte-acos/presentacion/bandeja-reporte-acos/bandeja-reporte-acos.component';
// import { NuevoContenedorComponent } from './nuevo-contenedor/nuevo-contenedor.component';
// import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SoporteRepository } from './soporte/domain/soporte.repository';
import { SoporteService } from './services/soporte.service';
import { GestionSalasService } from './services/gestionSalas.service';
import { GestionSalasRepository } from './matenimiento_salas/domain/mante_salas_repository';
import { ReportesRepository } from './reporte-ventas/domain/reportes.repository';
import { ReportesService } from './services/reportes.service';
import { ReporteVentaBandejaComponent } from './reporte-venta/presentacion/reporte-venta-bandeja/reporte-venta-bandeja.component';
import { BandejaCicloEmbarqueComponent } from './reporte-ciclo-embarque/presentacion/bandeja-ciclo-embarque/bandeja-ciclo-embarque.component';
import { OrderbyPipe } from './shared/components/orderby.pipe';
import { OperacionesRepository } from './mantenimientos/domain/operaciones.repository';
import { OperacionesService } from './services/operaciones.service';
import { BandejacobranzasComponent } from './finanzas/cobranzas/presentacion/bandejacobranzas/bandejacobranzas.component';
import { CobranzasRepository } from './finanzas/cobranzas/domain/cobranzas_repository';
import { FinanzasService } from './services/finanzas.service';
import { BandejaProyectosComponent } from './gestion-proyectos/presentacion/bandeja-proyectos/bandeja-proyectos.component';
import { NuevoProyectoComponent } from './gestion-proyectos/presentacion/nuevo-proyecto/nuevo-proyecto.component';
import { GestionProyectosRepository } from './gestion-proyectos/domain/gestionProyectos-repository';
import { GestionProyectosService } from './services/gestionProyectos.service';
import { ComercialService } from './services/comercial.service';
import { ComercialRepository } from './repositorio/comercial.repository';
import { SharedModule } from './shared/shared.module';
import { VisorComponent } from './comercial/reffer/visor-pdf/presentacion/visor/visor.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CryptoRepository } from './interfaces/crypto-repository';
import { CryptoService } from './services/crypto.service';
import { DialogTableComponent } from './dialog-table/dialog-table.component';
import { operacionService } from './services/operacion.service';
import { OperacionRepository } from './repositorio/operacion.repository';
import { IndicadoresAvisoLlegadaComponent } from './dashboard-aviso-llegada/presentacion/indicadores-aviso-llegada/indicadores-aviso-llegada.component';
import { IndicadorDesgloseComponent } from './dashboard-desglose/presentacion/indicador-desglose/indicador-desglose.component';
import { IndicadorDireccionamientoComponent } from './dashboard-direccionamiento/presentacion/indicador-direccionamiento/indicador-direccionamiento.component';
import { IndicadoresComportamientoOvComponent } from './dashboard-comportamiento-ov/presentacion/indicadores-comportamiento-ov/indicadores-comportamiento-ov.component';
import { GestionUsuarioRepository } from './gestion-usuario/domain/gestion-usuario-repository';
import { GestionUsuarioService } from './services/gestion-usuario.service';
import { BandejaSolicitudAnulacionComponent } from './finanzas/solicitud-anulacion/presentacion/bandeja-solicitud-anulacion/bandeja-solicitud-anulacion.component';
import { ReportePpsComponent } from './planeamiento/reportes/components/reporte-pps/reporte-pps.component';
import { VentasRepository } from './comercial/ventas/ventas.repository';
import { VentasService } from './comercial/ventas/ventas.service';
import { CustomerRepository } from './comercial/customer/customer.repository';
import { CustomerService } from './comercial/customer/customer.service';
import { PricingRepository } from './supply/pricing/repositorio/pricing.repository';
import { PricingService } from './supply/pricing/servicio/pricing.service';
import { ReporteTarifaRepository } from './supply/reportes/servicio/reporte-tarifa.repository';
import { ReporteTarifaService } from './supply/reportes/servicio/reporte-tarifa.service';
import { ReeferRepository } from './comercial/reefer/servicio/reefer.repository';
import { ReeferService } from './comercial/reefer/servicio/reefer.service';


//@ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    PageGestionvisitasComponent,
    // NuevoCon
    //Mantenimiento_servicioComponent
    OrderbyPipe,
    VisorComponent,
    // MainMaintenanceComponent,
    DialogTableComponent
  ],
  // NuevoCon
  imports: [
    PdfViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule, MatInputModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    NgChartsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    GeolocalizacionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    NgChartsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    ButtonModule,
    PdfViewerModule
    // NgxExtendedPdfViewerModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBa-RKxRHovcomFcKNKCWjx48WJeJ1STzI',
    // }),

  ],
  providers: [
    DatePipe,
    { provide: UserRepository, useClass: UserOperations }, { provide: APP_BASE_HREF, useValue: '/' },

    { provide: RolRepository, useClass: RolService },
    { provide: MenuRepository, useClass: ManMenuService },
    { provide: ProformaRepository, useClass: ProformaService },
    { provide: ClienteRepository, useClass: ClienteService },
    { provide: AlertaDocumentoRepository, useClass: AlertaDocumentoService },
    { provide: PreResRepository, useClass: PreResService },
    { provide: DashBoardRepository, useClass: DashBoardService },
    { provide: ComisionesRepository, useClass: ComisionesService },
    { provide: OvVersionarRepository, useClass: OvVersionarService },
    { provide: SoporteRepository, useClass: SoporteService },
    { provide: ReportesRepository, useClass: ReportesService },
    { provide: OperacionesRepository, useClass: OperacionesService },
    { provide: GestionSalasRepository, useClass: GestionSalasService },
    { provide: CobranzasRepository, useClass: FinanzasService },
    { provide: ComercialRepository, useClass: ComercialService },
    { provide: CryptoRepository, useClass: CryptoService },
    { provide: GestionProyectosRepository, useClass: GestionProyectosService },
    { provide: GestionUsuarioRepository, useClass: GestionUsuarioService },
    { provide: VentasRepository, useClass: VentasService },
    { provide: CustomerRepository, useClass: CustomerService },
    { provide: PricingRepository, useClass: PricingService },
    { provide: OperacionRepository, useClass: operacionService },
    { provide: ReporteTarifaRepository, useClass: ReporteTarifaService },
    { provide: ReeferRepository, useClass: ReeferService },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    // todo lo que se necesita que se instacie una vez se tieen que poner aca en el provider
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },//BarcodeScanner
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
    ConfirmationService,
    DialogService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
