import { Injectable } from '@angular/core';
import { VentasRepository } from './ventas.repository';
import { HttpClient } from '@angular/common/http';
import { ListarEntPendAprobRequest, TransaccionEstadoVinculacionRequest } from './componentes/vinculacion-empresas/domain/request/ventas-request';
import { ListarEntPendAprobResponse, TransaccionEstadoVinculacionResponse } from './componentes/vinculacion-empresas/domain/response/ventas-response';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ListarContratoPorLineaRequest, ListarCuotaContratoRequest, ListarCuotaNaveETDRequest, ReporteLineaContratoRequest, TransaccionCuotaContratoRequest, ValidarCuotaContratoRequest } from './cuota-linea-tipo-contrato/domain/cuota-linea-tipo-contrato.request';
import { ListarContratoPorLineaResponse, ListarCuotaContratoResponse, ListarCuotaNaveETDResponse, ReporteLineaContratoResponse, TransaccionCuotaContratoResponse, ValidarCuotaContratoResponse } from './cuota-linea-tipo-contrato/domain/cuota-linea-tipo-contrato.response';
import { GeneralTransaccionResponse } from 'src/app/operacion/tarja/domain/tarja.response';
import { anularRequerimientoRequest, mantenimientoRequerimientoRequest, rechazarRequerimientoRequest } from './requerimiento/domain/requerimiento.request';

@Injectable({
  providedIn: 'root'
})
export class VentasService extends VentasRepository {  

  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }

  ListarEntidadesPendAprob(fd: ListarEntPendAprobRequest): Observable<ListarEntPendAprobResponse> {
    return this.http.post<ListarEntPendAprobResponse>(`${environment.PATH_API}/ventascomercial/ListarEmpresasPorAprobacion`, fd);
  }
  TransaccionEstVinc(fd: TransaccionEstadoVinculacionRequest): Observable<TransaccionEstadoVinculacionResponse> {
    return this.http.post<TransaccionEstadoVinculacionResponse>(`${environment.PATH_API}/ventascomercial/TransaccionEstadoVinculacion`, fd);
  }
  ListarContrato(fd: ListarContratoPorLineaRequest): Observable<ListarContratoPorLineaResponse> {
    return this.http.post<ListarContratoPorLineaResponse>(`${environment.PATH_API}/ventascomercial/ListarContratoPorLinea`, fd);
  }
  ListarCuotaContrato(fd: ListarCuotaContratoRequest): Observable<ListarCuotaContratoResponse> {
    return this.http.post<ListarCuotaContratoResponse>(`${environment.PATH_API}/ventascomercial/ListarCuotaContrato`, fd);
  }
  TransaccionCuotaContrato(fd: TransaccionCuotaContratoRequest): Observable<TransaccionCuotaContratoResponse> {
    return this.http.post<TransaccionCuotaContratoResponse>(`${environment.PATH_API}/ventascomercial/TransaccionCuotaContrato`, fd);
  }

  ValidarCuotaContrato(fd: ValidarCuotaContratoRequest): Observable<ValidarCuotaContratoResponse> {
    return this.http.post<ValidarCuotaContratoResponse>(`${environment.PATH_API}/ventascomercial/ValidarCuotaContrato`, fd);
  }

  //#region REQUERIMIENTO

  override mantenimientoRequerimiento(prolrequest: mantenimientoRequerimientoRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/proformainterna/MantProformaInterna`, prolrequest)
  }
  override rechazarRequerimiento(rq: rechazarRequerimientoRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/Proformaexterna/rechazarRequerimiento`, rq)
  }
  override anularRequerimiento(rq: anularRequerimientoRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/proformainterna/anularrequisicion`, rq)
  }

  //#endregion
  ReporteLineaContrato(rq: ReporteLineaContratoRequest): Observable<ReporteLineaContratoResponse> {
    return this.http.post<ReporteLineaContratoResponse>(`${environment.PATH_API}/ventascomercial/ReporteCuotaLinContrato`, rq)
  }
  override ListCuotaNaveETD(rq: ListarCuotaNaveETDRequest): Observable<ListarCuotaNaveETDResponse> {
    return this.http.post<ListarCuotaNaveETDResponse>(`${environment.PATH_API}/ventascomercial/ListarCuotaNaveETD`, rq)
  }
}
