import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { readonlyMetadataTable } from './domain/readonly-data-table.domain';
import { UtilService } from 'src/app/services/util.service';
import { ConfirmationService, MenuItem, SortEvent } from 'primeng/api';
import { style } from './domain/readonly-data-table.domain';
import { Table } from 'primeng/table';
import { ContextMenu } from 'primeng/contextmenu';
import { RolRepository } from 'src/app/roles/domain/rol.repository';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { listarOpcionesTablaRequest, transaccionOpcionesTablaRequest } from 'src/app/roles/domain/request/rol_request';
import { newmetadate } from '../datatable/domain/datatable-domain';

@Component({
  selector: 'app-readonly-data-table',
  templateUrl: './readonly-data-table.component.html',
  styleUrls: ['./readonly-data-table.component.css']
})

export class ReadonlyDataTableComponent {
  bResult: any;
  isHovered = false;//Es para la simulaciond de hover de un checkbox

  constructor(
    public readonly util: UtilService,
    private confirmationService: ConfirmationService,
    private readonly rol: RolRepository,
    private readonly storage: StorageService,
    private readonly router: Router
  ) { }

  @ViewChild('dt1') table: Table
  @ViewChild('dt2') table2: Table

  @Input() caption: boolean = false;
  @Input() caption2: boolean = false;
  @Input() nombreListaHijos: string;
  @Input() dataTable: any[];
  @Input() title: string = ''
  @Input() filterGlobal: boolean = false;
  @Input() filterGlobalHijos: boolean = false;
  @Input() metadataTable: readonlyMetadataTable[] = [];
  @Input() key: string = ''
  @Input() scrollHeight: string = '400px'
  @Input() rows: any[] = [20, 30, 50]
  @Input() filter: boolean = true;
  @Input() customButtons: { icon: string; function?: () => void; toolTip?: string; loading?: boolean; disabled?: boolean }[] = [];
  @Input() style: style;
  @Input() typeFilter: 'row' | 'menu' = 'menu';
  @Input() id: string
  @Input() customLeyendas: { src?: string; function?: () => void; texto?: string }[] = [];
  @Output() Row = new EventEmitter<any>()
  data: any[] = []
  datatreeview: any[] = []
  metadatatable: readonlyMetadataTable[] = []
  paginator: boolean = true
  metadatechildren: any[] = []
  metadatepather: any[] = []
  valorResize = false
  frozen = false
  // @Input() style: style;
  // selectedDates: { [key: string]: Date } = {};

  Valor: string
  styleHeader: { [key: string]: string } = {}
  styleBody: { [key: string]: string } = {}
  filtrosGlobal: any[] = []
  filtrosGlobalHijos: any[] = []
  sortFieldPadres: string;
  sortOrderPadres: number;
  sortFieldHijos: string = '';
  sortOrderHijos: number;

  ngOnInit() {
    // this.sortFieldHijos='clientes'
    //==============================================NO BORRAR===========================================================
    // // Recuperar los datos desde sessionStorage
    // const ordenamientoColumnasPadres = sessionStorage.getItem('ordenamientoColumnasPadres');
    // const ordenamientoColumnasHijos = sessionStorage.getItem('ordenamientoColumnasHijos');

    // // Si los datos existen, parsearlos de vuelta a un objeto
    // if (ordenamientoColumnasPadres) {
    //   const sortFieldsPadres = JSON.parse(ordenamientoColumnasPadres);
    //   console.log(sortFieldsPadres);  // Aquí puedes usar los datos de ordenamiento
    //   this.sortFieldPadres=sortFieldsPadres[0].field
    //   this.sortOrderPadres=sortFieldsPadres[0].order
    // }

    // if (ordenamientoColumnasHijos) {
    //   const sortFieldsHijos = JSON.parse(ordenamientoColumnasHijos);
    //   console.log(sortFieldsHijos);  // Aquí puedes usar los datos de ordenamiento
    //   this.sortFieldHijos=sortFieldsHijos[0].field
    //   this.sortOrderHijos=sortFieldsHijos[0].order
    // }
    //============================================== FIN NO BORRAR===========================================================
    this.Valor = "7";
    if (this.style) {
      if (this.style.header) {
        const r = this.style.header
        this.styleHeader = {};
        if (r.align) { this.styleHeader['text-align'] = r.align }
        if (r.size) { this.styleHeader['font-size'] = r.size }
        if (r.justify) { this.styleHeader['justify-content'] = r.justify }
      }
      if (this.style.body) {
        const r = this.style.body
        this.styleBody = {};
        if (r.align) { this.styleBody['text-align'] = r.align }
        if (r.size) { this.styleBody['font-size'] = r.size }
        if (r.justify) { this.styleBody['justify-content'] = r.justify }
      }
    }
    this.caption = this.filterGlobal === true || this.title != '' ? true : false
    this.caption2 = this.filterGlobalHijos === true || this.title != '' ? true : false
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mainmetadata = []
    // this.footer = false
    this.metadataTable.forEach(r => {
      this.mainmetadata.push(r)
    })
    this.metadatatable = this.metadataTable

    const metadatepather = this.metadatatable.filter(r => r.type != 'array')
    this.metadatechildren = this.metadatatable.filter(r => r.type == 'array')
    this.data = this.dataTable
    // metadatechildren.forEach(r => {

    //   datatreeview
    // })


    if (this.data.length > 20 && this.paginator == true) { this.paginator = true }


    if (changes['metadataTable'] && this.metadataTable && this.metadataTable.length > 0) {

      this.metadataTable.forEach(r => {
        this.filtrosGlobal.push(r.field);
        const item = { field: r.field, header: r.title }
        this.cols.push(item)
        // this.cols.push(r);
      });
      this._selectedColumns = this.cols;


    }

    if (this.id) {
      const request = <listarOpcionesTablaRequest>{}
      request.usuario = this.storage.get('usuario')
      request.opcion = this.router.url
      request.tabla = this.id.toString().replace(/'/g, "")
      request.tab = ''
      // console.log(request)
      this.rol.listarOpcionesTabla(request).subscribe(response => {

        if (response.datos.status === 200) {
          // console.log(response.datos.result)
          this._selectedColumns = []
          const valores = this.metadataTable.filter(r => response.datos.result.some(e => e.field === r.field))
          // console.log('valores', valores)
          valores.forEach(x => {
            const item = { field: x.field, header: x.title }
            this._selectedColumns.push(item)
          })

          setTimeout(() => {
            this.metadatatable = this.metadataTable.filter(r =>
              response.datos.result.some(e => e.field === r.field)
            );
            this.mainmetadata = this.mainmetadata.filter(r =>
              !this.metadatatable.some(e => e.field === r.field)
            );
          }, 0);

        }
      })
    }

  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    console.log('Cambio mi metadata')
    const request = <listarOpcionesTablaRequest>{}
    request.usuario = this.storage.get('usuario')
    request.opcion = this.router.url
    request.tabla = this.id.toString().replace(/'/g, "")
    request.tab = ''
    console.log(request)
    this.rol.listarOpcionesTabla(request).subscribe(response => {

      if (response.datos.status === 200) {
        console.log(response.datos.result)
        this._selectedColumns = []
        const valores = this.metadataTable.filter(r => response.datos.result.some(e => e.field === r.field))
        console.log('valores', valores)
        valores.forEach(x => {
          const item = { field: x.field, header: x.title }
          this._selectedColumns.push(item)
        })

        setTimeout(() => {
          this.metadatatable = this.metadataTable.filter(r =>
            response.datos.result.some(e => e.field === r.field)
          );
        }, 0);

      }
    })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // console.log('cambios')
  }

  DespliegueHijos(row) {
    // console.log(row);
    // if (this.metadataTable && this.metadataTable.length > 0) {
    //   this.filtrosGlobalHijos = this.metadataTable[this.metadataTable.length - 1].metadata
    //     .filter(r => row.hasOwnProperty(r.field)) // Filtra solo los campos que existen en row
    // }

    this.metadataTable[this.metadataTable.length - 1].metadata.forEach(r => {
      this.filtrosGlobalHijos.push(r.field);
      // this.cols.push(r);
    });


    // console.log(this.filtrosGlobalHijos)
  }

  ver(dato) {
    // console.log("asd", dato)
  }

  selectedDate: Date;
  handleDateSelect(event: any) {
    this.selectedDate = event;
  }
  selectDropdown: string
  handleDropdownSelect(event: any) {
    this.selectDropdown = event
    // console.log(event)
  }
  //   handleDateSelect(event: any, key: string) {
  //     this.selectedDates[key] = event;
  // }
  // handleDateSelect(event, rowdata) {
  //   rowdata.selectedDate = event; // Enable the button only for the current row
  // }
  visible: boolean;
  toggleState: boolean = true;

  // toggleState: boolean = true;

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //   if (this.toggleState) {
  //     // Funcion 1
  //     textarea.style.height = 'auto';
  //     textarea.style.height = textarea.scrollHeight + 'px';
  //     this.valorResize = true
  //   } else {
  //     // Funcion 2
  //     textarea.style.height = null; // Reset the height style
  //     textarea.rows = 1;
  //     this.valorResize = false

  //   }
  //   this.toggleState = !this.toggleState; // toggle the state
  // }
  visibleP_Dialog_General: boolean;
  visibleDialog: boolean;
  visibleTextArea: boolean;
  visibleTextAreaEdit: boolean;
  datoTextArea: string
  tituloP1_Dialog: string

  funcionx: any;
  selectedRow: any; // Variable para guardar el row seleccionado
  showDialog(row: any, title: string, field: string, modal: string, funcion: Function, dato: string) {
    this.visibleP_Dialog_General = true
    this.visibleDialog = true
    this.visibleTextAreaEdit = true;
    this.visibleTextArea = true;
    this.tituloP1_Dialog = title
    if (modal == "textArea") {
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea = true
      this.visibleTextAreaEdit = false
      this.datoTextArea = dato

      // this.datoTextArea = row.doov_mbl;
      this.funcionx = funcion; // Guardar la función
      this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    } else if (modal == "textAreaEdit") {
      console.log(row)
      this.visibleDialog = false
      this.visibleTextArea = false
      this.visibleTextAreaEdit = true
      this.datoTextArea = row.nvia_usercomments

      // this.datoTextArea = row.doov_mbl;
      this.funcionx = funcion; // Guardar la función
      this.selectedRow = row; // Guardar el row seleccionado
      // this.mensajeDialog = { datoTextArea: row.doov_mbl}
    }

  }

  onConfirm() {
    if (this.funcionx && this.selectedRow) {
      this.funcionx({ event: {}, row: this.selectedRow, dato: this.datoTextArea });
    }
  }

  // showDialog() {
  //   const textarea = document.querySelector('textarea');
  //     textarea.rows = 10;
  // }



  // showDialog() {
  //   // this.visible = true;
  //   const textarea = document.querySelector('textarea');
  // this.showAllRows = !this.showAllRows;
  // textarea.rows = this.showAllRows ? null : 1;
  // }

  verdato(dato: any) {
    console.log(dato)
  }

  isDisabled() {
    this.selectedDate = null;
  }
  isDisabledDropdwon() {
    this.selectDropdown = null;
  }
  //   isDisabled(key: string) {
  //     this.selectedDates[key] = null;
  // }


  // PanelConfirmacion(funcion: (dato: any) => void) {//mensaje:string,
  //   this.confirmationService.confirm({
  //     target: event.target,
  //     message: "ABCD",
  //     icon: 'pi pi-exclamation-triangle',

  //     accept: () => {        
  //       funcion(dato);
  //       console.log("Se hace")
  //     },
  //     reject: () => {
  //     }
  //   });
  // }

  // des(): string {
  //   alert('zzz')
  //   return ''
  // }

  generateStyle(data: any): { [key: string]: string } {
    let value = {}
    if (data) {
      if (data.align) { value['text-align'] = data.align }
      if (data.size) { value['font-size'] = data.size }
      if (data.justify) { value['justify-content'] = data.justify }
      if (data.width) { value['width'] = data.width }
    }
    return value
  }

  viewData() {
    console.log('DATATABLE', this.dataTable)
    // console.log('PRECARGA', this.precarga)
    console.log('KEY', this.key)
    // console.log('COLS', this.cols)
    // console.log('FILTRO GLOBAL', this.filtrosGlobal)
    // console.log('METADATATABLE', this.metadataTable)
    // console.log('CAPTION', this.caption)
    // console.log('TABLE PRIME', this.table)
    // if (this.group) {
    //   console.log('GROUP', this.group);
    //   console.log('GROUP VALUE', this.group.value);
    // }

  }

  isPopupVisible: boolean = false;

  // Método para mostrar el popup
  showPopup() {
    this.isPopupVisible = true;
  }

  // Método para ocultar el popup
  hidePopup() {
    this.isPopupVisible = false;
  }

  bool(value: any): boolean {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return !!value; // Convierte a booleano para otros casos (como null, undefined, 0, etc.)
  }

  handleClick() {
    console.log("ohala")
  }

  getButtonStyle(isActive: boolean): any {
    if (isActive) {
      return {}; // Estilo por defecto cuando es true
    } else {
      return {
        'background-color': 'white',
        'border': '2px solid #ced4da',
        'transition': 'background-color .2s,color .2s,border-color .2s,box-shadow .2s'
      };
    }
  }
  clear(table: Table) {
    table.clear();
    this.filterTableData.emit([]);
  }
  clear2(table2: Table) {
    table2.clear();
  }

  selectedProduct: any;
  @Input() contextMenu: MenuItem[]
  @ViewChild('expmenu') expmenu: ContextMenu
  @Output() selectedRowData = new EventEmitter<any>()
  onRowRightClick(event: MouseEvent, rowdata: any) {
    event.preventDefault();
    this.selectedRowData.emit(rowdata);
  }

  actualizarTabla(tabla) {
    console.log("read", tabla._value)
  }


  // Usar ViewChild para obtener la referencia de la tabla
  @ViewChild('dt1') dt1: Table | undefined;

  // Método para filtrar globalmente
  onGlobalFilter(value: string): void {
    if (this.dt1) {
      // Aplica el filtro global a la tabla
      this.dt1.filterGlobal(value, 'contains');
      // let filtrado =this.dt1.filterGlobal(value, 'contains');
      // console.log("xxx#",filtrado)
      // // Esperar a que los datos se actualicen (esto es importante)
      // setTimeout(() => {
      //   // Accede a los datos filtrados
      //   const filteredData = this.dt1._value;  // Aquí accedes a los datos filtrados
      //   console.log('Datos filtrados:', filteredData);
      // }, 0);  // Usar setTimeout para asegurar que la tabla haya sido actualizada
    }
  }

  @Output() filterTableData = new EventEmitter<any[]>();

  filteredData: any[] = [];

  manualFilter(value: string): void {
    if (this.dt1 && this.dt1._value) {
      const searchValue = value.toString().toLowerCase();
      this.filteredData = this.dt1._value.filter(item => {
        return this.containsInParentProperties(item, searchValue);
      });

      console.log('Datos filtrados:', this.filteredData);

      // Emitir los datos filtrados a TS2
      this.filterTableData.emit(this.filteredData);
    }
  }

  containsInParentProperties(item: any, searchValue: string): boolean {
    for (let key in item) {
      if (item.hasOwnProperty(key)) {
        const propertyValue = item[key];
        if (propertyValue && propertyValue.toString) {
          if (propertyValue.toString().toLowerCase().includes(searchValue)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  checkList: any[] = []
  onChangeCheckList(event: Event, row: any): void {

    if (row.index === undefined || row.index === null || row.index === '') {
      console.log('La fila necesita contener un atributo index valido.')
      return
    }

    if (event['checked']) {
      this.checkList.push(row)
    } else {
      this.checkList = this.checkList.filter(item => item.index !== row.index)
    }
  }

  resetCheckList(): void {
    this.checkList = []
  }
  dropdownList: any[] = []
  rowdropdown: any[] = []
  listacombinada: any[] = []
  onChangeDropdownList(event, row) {
    console.log(event)
    console.log(row)
    event.value//valor q se eligió
    this.dropdownList.push(event.value)
    this.rowdropdown.push(row)
    console.log(this.dropdownList)
    console.log(this.rowdropdown)

    this.listacombinada = this.dropdownList.map((value, index) => ({
      dropdownValue: value,
      rowValue: this.rowdropdown[index]
    }));

    console.log("Lista combinada ", this.listacombinada);
  }

  onButtonClick(event: MouseEvent, item2: any): void {
    event.stopPropagation();  // Detiene la propagación del clic

    // Llama a la función de tu lógica aquí
    item2.function(this.listacombinada);
  }

  isSorted: boolean = null;
  @ViewChild('dt2') dt2: Table;
  customSort(event: SortEvent) {


    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });

    this.sortFieldHijos = event.field
    this.sortOrderHijos = event.order


  }



  verDetalle() {
    console.log(this.table)
    console.log(this.table2)
    // this.table2._sortField = 'cliente'
    this.sortFieldHijos = 'cliente'
  }

  onChangeButtonArchivos(rowdata) {
    if (!rowdata.ovar_archivo) {
      return;
    }
    const extension = rowdata.ovar_descrip.split('.').pop();
    const mimeType = this.getMimeType(extension);
    const fileName = `archivo_${rowdata.ovar_codigo}.${extension}`;

    const byteCharacters = atob(rowdata.ovar_archivo);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

  }

  getMimeType(extension: string): string {
    const mimeTypes: { [key: string]: string } = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'gif': 'image/gif',
      'pdf': 'application/pdf',
      'txt': 'text/plain',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'zip': 'application/zip',
    };

    return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
  }

  botonDescargar: boolean = false;
  desbloquearBotonDescargar(event: MouseEvent, item2: any, row: any): void {
    event.stopPropagation();
    if (item2.header === 'miCabecera' || (row.ovar_archivo && row.ovar_archivo.trim() !== '')) {
      this.botonDescargar = true;
    } else {
      this.botonDescargar = false;
    }
  }

  esBotonHabilitado(item2: any, row: any): boolean {
    return item2.header === 'miCabecera' || (row.ovar_archivo && row.ovar_archivo.trim() !== '');
  }



  saveOptionColumn() {
    const request = <transaccionOpcionesTablaRequest>{}

    request.usuario = this.storage.get('usuario')
    request.opcion = this.router.url
    request.tabla = this.id.toString().replace(/'/g, "")
    request.tab = ''

    let opcionesmetadata = []

    this.metadatatable.forEach(r => {
      opcionesmetadata.push({ tabl_field: r.field })
    })

    request.opciones = opcionesmetadata
    request.tipo = 'I'

    this.rol.transaccionOpcionesTabla(request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Configuración asignada.' })
      }
    })

  }


  cols: any[] = []
  _selectedColumns: any[] = []
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns
  }

  set selectedColumns(val: any[]) {

    this._selectedColumns = this.cols.filter((col) =>
      val.some((v) => v.field === col.field)
    )

    this.metadatatable = this.metadataTable.filter(r =>
      this._selectedColumns.some(e => e.field === r.field)
    )
  }


  optionColumns: boolean
  showOptionColumns() {
    this.optionColumns = true
  }

  mainmetadata: newmetadate[] = []


  saveOptionColumns() {
    console.log(this.metadatatable)

    const request = <transaccionOpcionesTablaRequest>{}

    request.usuario = this.storage.get('usuario')
    request.opcion = this.router.url
    request.tabla = this.id.toString().replace(/'/g, "")
    request.tab = ''

    let opcionesmetadata = []

    this.metadatatable.forEach((r, i) => {
      opcionesmetadata.push({ tabl_field: r.field, tabl_index: i })
    })

    request.opciones = opcionesmetadata
    request.tipo = 'I'
    console.log(request)
    this.rol.transaccionOpcionesTabla(request).subscribe(response => {
      if (response.datos.status === 200) {
        this.util.ShowMessage({ mensaje: 'Configuración asignada.' })
      }
    })

  }
  onRowSelect(event) { this.Row.emit(event.data) }
}
