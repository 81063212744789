import { DetalleServiciosLocalesRequest, ServiciosLocalesRequest, BuscarFavoritosPorViaRequest, BuscarServicio_ByViaRegimenRequest, ConsultarContratoAyudaPivotOVRequest, ConsultarTarifaRequest, ConsultarTariffDetailByIdRequest, GetServiceCalculatedByTariffRequest, ListaDetCotizacionOVArchivoRequest, ListaDetCotizacionOVEventoActualizarRequest, ListaDetCotizacionOVFleteActualizarRequest, ListaDetCotizacionOVItinerarioRequest, ListaDetCotizacionOVServicioActualizarRequest, ConsultarEntidadServicio_Request, ConsultarDTECliente_Request, ConsultarDirecENTC_Request, ConsultarEntidadAcuerdo_Request, ConsultarEntidadRelacionadosRequest, BuscarPorEntidad_Request, GetCotizacionOVArchivoRequest, ObtenerFormatosCorreos, FormatoEnvioCorreos, ConcluirCabCotizacionRequest } from './../comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BuscarNavesItinerarioRequest, InsertItinerarioRefferRequest, InsertServicioRefferRequest, ListarDropdownRequest, ListarItinerariosRefferRequest, ListarNavesRequest, ListarNuevasReservaRequest, ListarNuevosClientesRequest, ListarServiciosRefferMultiListaRequest, ListarServiciosRefferRequest, NuevaReservaRequest, NuevoPerfilClienteRequest, operacionesNaveRequest, transbordoReservaRequest, transbordoTrackingRequest, UpdateNuevosClienteRequest, updatePasswordRefferRequest, updateReservaRefferRequest } from '../comercial/reffer/domain/reffer-request';
import { BuscarNavesItinerarioResponse, GeneralResponse, ListarDropdownResponse, ListarItinerariosRefferResponse, ListarNavesResponse, ListarNuevaReservaResponse, ListarNuevosClientesResponse, ListarServiciosRefferMultiListaResponse, ListarServiciosRefferResponse, transbordoTrackingResponse } from '../comercial/reffer/domain/reffer-response';
import { ComercialRepository } from '../repositorio/comercial.repository';
import { ListarOrdenesVentaRequest, CrudOrdenesVentaRequest, listAllOVRequest, listNaveViajePorPuertoRequest, } from '../comercial/orden-venta/domain/request/comercial-orden-venta-request';
import { ListarOrdenesVentaResponse, CrudOrdenesVentaResponse, ListaDetCotizacionOVFleteActualizarResponse, ListaDetCotizacionOVServicioActualizarResponse, ListaDetCotizacionOVEventoActualizarResponse, ListaDetCotizacionOVArchivoResponse, BuscarServicio_ByViaRegimenResponse, BuscarFavoritosPorViaResponse, ListaDetCotizacionOVItinerarioResponse, ConsultarContratoAyudaPivotOVoResponse, ConsultarTarifaResponse, ConsultarTariffDetailByIdResponse, ConsultarServiceCalculatedByTariffResponse, DetalleServiciosLocalesResponse, ServiciosLocalesResponse, InsertarServiciosLocalesResponse, ConsultarEntidadServicioResponse, ConsultarDTEClienteResponse, ConsultarDirecENTCResponse, ConsultarEntidadAcuerdoResponse, ConsultarEntidadRelacionadosResponse, BuscarPorEntidad_Response, GetCotizacionOVArchivoResponse, PreVisualizarFormatosResponse, FormatoEnvioCorreosResponse, ConcluirCabCotizacionErrorResponse } from '../comercial/orden-venta/domain/response/comercial-orden-venta-response';
import { searchTrackingShipmentRequest } from '../comercial/tracking-shipment/domain/tracking-shipment-request';
import { searchTrackingShipmentResponse } from '../comercial/tracking-shipment/domain/tracking-shipment-response';
import {  ConsultarCabCotizacionAprobacionRequest, GenerarCabCotizacionRequest, NotificarFletamentoCotizacionRequest, TransportistaRequest } from '../comercial/cotizacion/domain/request/cotizacion-request';
import { TransportistaResponse } from '../proforma/domain/response/proforma_response';
import { CabCotizacionAprobacionResponse, GenerarCabCotizacionResponse, NotificarFletamentoCotizacionResponse, TransportistaCotizacionResponse, VersionarCabCotizacionResponse } from '../comercial/cotizacion/domain/response/cotizacion-response';
import { ResponseLogin, UserEntity } from '../usuario/domain/user-entity';
// import { Actualiza_Campos_Viaje_ItenerarioRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, SINC_API_TT_WEBRequest, SolicitudAPI_MSC_HAPAGRequest } from '../comercial/customer/domain/sinc-api-tt-request';
// import { Actualiza_Campos_Viaje_ItenerarioResponse, ListarOvApiTTResponse, SolicitudAPI_MSC_HAPAGResponse } from '../comercial/customer/domain/sinc-api-tt-response';
// import { ListarCuotaEjecutivoRequest, ListarEjecutivosCuotaRequest, TransaccionEjecutivosCuotaRequest } from '../comercial/mantenimiento/domain/request/mantenimiento-request';
// import { ListarCuotaEjecutivoResponse, ListarEjecutivosCuotaResponse, TransaccionEjecutivosCuotaResponse } from '../comercial/mantenimiento/domain/response/mantenimiento-response';
// import { Actualiza_Campos_Viaje_ItenerarioRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, ObtenerCantTramosRequest, ObtenerViajesAlAzarRequest, SINC_API_TT_WEBRequest, SolicitudAPI_HAPAGRequest, SolicitudAPI_MSCRequest } from '../comercial/customer/domain/sinc-api-tt-request';
// import { Actualiza_Campos_Viaje_ItenerarioResponse, ListarOvApiTTResponse, ObtenerCantTramosResponse, ObtenerViajesAlAzarResponse, SolicitudAPI_HAPAGResponse, SolicitudAPI_MSCResponse } from '../comercial/customer/domain/sinc-api-tt-response';



import { Actualiza_Campos_Viaje_ItenerarioRequest, EnviarCorreoActualizacionTramosRequest, EnviarCorreoErrorActualizarTramosRequest, ListarOvApiTTRequest, ManejoErrores_Flujo_ApiRequest, ObtenerCantTramosRequest, ObtenerViajesAlAzarRequest, SINC_API_TT_WEBRequest, SolicitudAPI_HAPAGRequest, SolicitudAPI_MSCRequest } from '../comercial/customer/domain/sinc-api-tt-request';
import { Actualiza_Campos_Viaje_ItenerarioResponse, EnviarCorreoActualizacionTramosResponse, EnviarCorreoErrorActualizarTramosResponse, ListarOvApiTTResponse, ObtenerCantTramosResponse, ObtenerViajesAlAzarResponse, SolicitudAPI_HAPAGResponse, SolicitudAPI_MSCResponse } from '../comercial/customer/domain/sinc-api-tt-response';
import { ListarCuotaEjecutivoRequest, ListarEjecutivosCuotaRequest, TransaccionEjecutivosCuotaRequest, ImportarCuotasEjecutivoRequest, ListarEntidadesRequest, ListarCuentasBancariasRequest, ListarLimiteCreditoRequest, ConsultarDireccionEntidadRequest, ListarAuditoriaRequest, ValidarDocIdentidadRequest, ListarHistorialRequest, ListarArchivoEntidadRequest, TransaccionEntidadBloqueoRequest, ListarUbigeosRequest, TransaccionEntidadRelacionadosRequest, SincronizarEntidadSapRequest, ListarNaveRequest, TransaccionNaveRequest } from '../comercial/mantenimiento/domain/request/mantenimiento-request';
import { ListarCuotaEjecutivoResponse, ListarEjecutivosCuotaResponse, TransaccionEjecutivosCuotaResponse, ResponseImportarCuotasEjecutivo, ListarEntidadesResponse, ListarCuentasBancariasResponse, ListarLimiteCreditoResponse, ConsultarDireccionEntidadResponse, ListarAuditoriaResponse, ValidarDocIdentidadResponse, ListarHistorialResponse, ListarArchivosEntidadResponse, TransaccionEntidadBloqueoResponse, ListarUbigeosResponse, TransaccionEntidadRelacionadosResponse, SincronizarEntidadSapResponse, ListarNaveResponse, TransaccionNaveResponse } from '../comercial/mantenimiento/domain/response/mantenimiento-response';
import { ListarConsultaIntegralRequest } from '../comercial/reportes/domain/request/comercial-reportes-request';
import { ListarConsultantegralResponse } from '../comercial/reportes/domain/response/comercial-reportes-response';
import { BuscarSobrestadiaRequest, SobrestadiaTNSRequest, TransportistaSobrestadiaRequest } from '../comercial/sobrestadia/domain/request/sobrestadia-request';
import { BuscarContratoByTransportistaResponse, BuscarSobrestadiaResponse, TransaccionSobrestadiaResponse } from '../comercial/sobrestadia/domain/response/sobrestadia-response';
import { BuscarFormatosPorAvisoRequest, BuscarGestionDeAvisosRequest, GetFormatCorreosGestionDeAvisosRequest, getNaveViajeByTransportistaRequest } from '../comercial/gestion-de-avisos/domain/request/gestion-de-avisos-request';
import { BuscarFormatosPorAvisoResponse, BuscarGestionDeAvisosResponse, GetFormatCorreosGestionDeAvisosResponse, ListarNaveViajeByTransportistaResponse } from '../comercial/gestion-de-avisos/domain/response/gestion-de-avisos-response';
@Injectable({
  providedIn: 'root'
})
export class ComercialService implements ComercialRepository {

  constructor(
    private readonly http: HttpClient
  ) { }     
  
  //#region REFFER

  NuevoPerfil(request: NuevoPerfilClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/NuevoPerfil`, request);
  }

  ListarNuevosClientes(request: ListarNuevosClientesRequest): Observable<ListarNuevosClientesResponse> {
    return this.http.post<ListarNuevosClientesResponse>(`${environment.PATH_API}/reffer/ListarNuevoCliente`, request);
  }

  UpdateNuevosCliente(request: UpdateNuevosClienteRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatenuevocliente`, request);
  }

  ListarDropdown(request: ListarDropdownRequest): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarDropdown`, request);
  }

  NuevaReserva(request: NuevaReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/insertsolicitudreserva`, request);
  }

  ListarNuevasReserva(request: ListarNuevasReservaRequest): Observable<ListarNuevaReservaResponse> {
    return this.http.post<ListarNuevaReservaResponse>(`${environment.PATH_API}/reffer/ListarNuevasReserva`, request);
  }

  InsertServicioReffer(request: InsertServicioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertServicioReffer`, request);
  }

  ListarServiciosReffer(request: ListarServiciosRefferRequest): Observable<ListarServiciosRefferResponse> {
    return this.http.post<ListarServiciosRefferResponse>(`${environment.PATH_API}/reffer/ListarServiciosReffer`, request);
  }

  InsertItinerarioReffer(request: InsertItinerarioRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/InsertItinerarioReffer`, request);
  }

  ListarItinerariosReffer(request: ListarItinerariosRefferRequest): Observable<ListarItinerariosRefferResponse> {
    return this.http.post<ListarItinerariosRefferResponse>(`${environment.PATH_API}/reffer/ListarItinerariosReffer`, request);
  }

  ListarNaves(): Observable<ListarDropdownResponse> {
    return this.http.post<ListarDropdownResponse>(`${environment.PATH_API}/reffer/ListarNaves`, '');
  }

  updateReservaReffer(request: updateReservaRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updateReserva`, request);
  }

  updatePasswordReffer(request: updatePasswordRefferRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/updatePassword`, request);
  }

  BuscarNavesItinerario(request: BuscarNavesItinerarioRequest): Observable<BuscarNavesItinerarioResponse> {
    return this.http.post<BuscarNavesItinerarioResponse>(`${environment.PATH_API}/reffer/BuscarNavesItinerario`, request);
  }

  ListarNavesNext(request: ListarNavesRequest): Observable<ListarNavesResponse> {
    return this.http.post<ListarNavesResponse>(`${environment.PATH_API}/reffer/ListarNavesNext`, request);
  }

  operacionesNave(request: operacionesNaveRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/operacionesNave`, request);
  }

  transbordoReserva(request: transbordoReservaRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/reffer/transbordoReserva`, request);
  }

  transbordoTracking(request: transbordoTrackingRequest): Observable<transbordoTrackingResponse> {
    return this.http.post<transbordoTrackingResponse>(`${environment.PATH_API}/reffer/transbordoTracking`, request);
  }

  //#endregion

  //#region SEGUIMIENTO DE EMBARQUE

  searchTrackingShipment(prolrequest: searchTrackingShipmentRequest): Observable<searchTrackingShipmentResponse> {
    return this.http.post<searchTrackingShipmentResponse>(`${environment.PATH_API}/comercial/searchTrackingShipment`, prolrequest);
  }

  //#endregion

  ListarOrdenesVenta(prolrequest: ListarOrdenesVentaRequest): Observable<ListarOrdenesVentaResponse> {
    return this.http.post<ListarOrdenesVentaResponse>(`${environment.PATH_API}/comercial/BuscarCotizacionesOrdenVenta`, prolrequest);
  }

  CrudOrdenesVenta(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_OV`, prolrequest);
  }

  CrudOrdenesVentaEditar(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_OV_editar`, prolrequest);
  }

  listAllOV(prolrequest: listAllOVRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarAllOrdenVenta`, prolrequest);
  }

  //Daniel 03/09/24
  listNaveViajePorPuerto(prolrequest: listNaveViajePorPuertoRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/ListarNaveViajePorPuerto`, prolrequest);
  }
  listarServiciosRefferMultiLista(prolrequest: ListarServiciosRefferMultiListaRequest): Observable<ListarServiciosRefferMultiListaResponse> {
    return this.http.post<ListarServiciosRefferMultiListaResponse>(`${environment.PATH_API}/reffer/ConsultaServiciosReffer`, prolrequest);
  }

  OVFleteActualizar(prolrequest: ListaDetCotizacionOVFleteActualizarRequest): Observable<ListaDetCotizacionOVFleteActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVFleteActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Flete`, prolrequest);
  }

  OVServicioActualizar(prolrequest: ListaDetCotizacionOVServicioActualizarRequest): Observable<ListaDetCotizacionOVServicioActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVServicioActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Servicio`, prolrequest);
  }

  OVEventoActualizar(prolrequest: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVEventoActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Evento`, prolrequest);
  }

  OVEventoInsertar(prolrequest: ListaDetCotizacionOVEventoActualizarRequest): Observable<ListaDetCotizacionOVEventoActualizarResponse> {
    return this.http.post<ListaDetCotizacionOVEventoActualizarResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Evento`, prolrequest);
  }

  OVGetArchivos(prolrequest: GetCotizacionOVArchivoRequest): Observable<GetCotizacionOVArchivoResponse> {
    return this.http.post<GetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Archivos`, prolrequest);
  }

  OVArchivoInsertar(prolrequest: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse> {
    return this.http.post<ListaDetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Archivo`, prolrequest);
  }

  OVArchivoActualizar(prolrequest: ListaDetCotizacionOVArchivoRequest): Observable<ListaDetCotizacionOVArchivoResponse> {
    return this.http.post<ListaDetCotizacionOVArchivoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Archivo`, prolrequest);
  }

  OVItinerarioInsertar(prolrequest: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse> {
    return this.http.post<ListaDetCotizacionOVItinerarioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Det_Cotizacion_OV_Itinerario`, prolrequest);
  }

  OVItinerarioActualizar(prolrequest: ListaDetCotizacionOVItinerarioRequest): Observable<ListaDetCotizacionOVItinerarioResponse> {
    return this.http.post<ListaDetCotizacionOVItinerarioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Det_Cotizacion_OV_Itinerario`, prolrequest);
  }

  BuscarServicio_ByViaRegimen(prolrequest: BuscarServicio_ByViaRegimenRequest): Observable<BuscarServicio_ByViaRegimenResponse> {
    return this.http.post<BuscarServicio_ByViaRegimenResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Servicio_ByViaRegimen`, prolrequest);
  }

  BuscarFavoritosPorVia(prolrequest: BuscarFavoritosPorViaRequest): Observable<BuscarFavoritosPorViaResponse> {
    return this.http.post<BuscarFavoritosPorViaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_FavoritosPorVia`, prolrequest);
  }

  BuscarContratoAyudaPivot(prolrequest: ConsultarContratoAyudaPivotOVRequest): Observable<ConsultarContratoAyudaPivotOVoResponse> {
    return this.http.post<ConsultarContratoAyudaPivotOVoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Contrato_AyudaPivotOV`, prolrequest);
  }

  BuscarTarifasServiciosLocales(prolrequest: ConsultarTarifaRequest): Observable<ConsultarTarifaResponse> {
    return this.http.post<ConsultarTarifaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Tarifa`, prolrequest);
  }

  BuscarTariffDetailById(prolrequest: ConsultarTariffDetailByIdRequest): Observable<ConsultarTariffDetailByIdResponse> {
    return this.http.post<ConsultarTariffDetailByIdResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_TariffDetailById`, prolrequest);
  }

  GetServiceCalculatedByTariff(prolrequest: GetServiceCalculatedByTariffRequest): Observable<ConsultarServiceCalculatedByTariffResponse> {
    return this.http.post<ConsultarServiceCalculatedByTariffResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_ServiceCalculatedByTariff`, prolrequest);
  }

  ActualizarServiciosLocales(prolrequest: ServiciosLocalesRequest): Observable<ServiciosLocalesResponse> {
    return this.http.post<ServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_EDITAR_ServicioLocalOV`, prolrequest);
  }

  ActualizarDetalleServiciosLocales(prolrequest: DetalleServiciosLocalesRequest): Observable<DetalleServiciosLocalesResponse> {
    return this.http.post<DetalleServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_EDITAR_DetalleServicioLocalOV`, prolrequest);
  }

  InsertarServiciosLocales(prolrequest: ServiciosLocalesRequest): Observable<InsertarServiciosLocalesResponse> {
    return this.http.post<InsertarServiciosLocalesResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_ServicioLocalOV`, prolrequest);
  }

  BuscarEntidadServicio(prolrequest: ConsultarEntidadServicio_Request): Observable<ConsultarEntidadServicioResponse> {
    return this.http.post<ConsultarEntidadServicioResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Entidad_Servicio`, prolrequest);
  }

  BuscarDTECliente(fd: ConsultarDTECliente_Request): Observable<ConsultarDTEClienteResponse> {
    return this.http.post<ConsultarDTEClienteResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Relacion_DTE_Cliente`, fd);
  }

  BuscarDirecENTC(fd: ConsultarDirecENTC_Request): Observable<ConsultarDirecENTCResponse> {
    return this.http.post<ConsultarDirecENTCResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Direccion_Por_Entidad`, fd);
  }

  BuscarEntidadAcuerdo(fd: ConsultarEntidadAcuerdo_Request): Observable<ConsultarEntidadAcuerdoResponse> {
    return this.http.post<ConsultarEntidadAcuerdoResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Entidad_Acuerdo`, fd);
  }

  BuscarRelacionadosPorEntidadPadre(fd: ConsultarEntidadRelacionadosRequest): Observable<ConsultarEntidadRelacionadosResponse> {
    return this.http.post<ConsultarEntidadRelacionadosResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Relacionados_Por_Entidad_Padre`, fd);
  }

  BuscarPorEntidad(fd: BuscarPorEntidad_Request): Observable<BuscarPorEntidad_Response> {
    return this.http.post<BuscarPorEntidad_Response>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_ENTCSS_Todos`, fd);
  }

  CrudComercialCotizacion(prolrequest: CrudOrdenesVentaRequest): Observable<CrudOrdenesVentaResponse> {
    return this.http.post<CrudOrdenesVentaResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CRUD_Cab_Cotizacion_COT`, prolrequest);
  }

  ObtenerTransportistaPorCotizacion(fd: TransportistaRequest): Observable<TransportistaCotizacionResponse> {
    return this.http.post<TransportistaCotizacionResponse>(`${environment.PATH_API}/comercial/GetTransportistaByCotizacion`, fd);
  }

  generarOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse> {
    return this.http.post<GenerarCabCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Cab_Cotizacion_OV_GenerarOV`, fd);
  }

  versionarOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<VersionarCabCotizacionResponse> {
    return this.http.post<VersionarCabCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Cab_Cotizacion_OV_Versionar`, fd);
  }

  NotificarFletamentoCotizacion(fd: NotificarFletamentoCotizacionRequest): Observable<NotificarFletamentoCotizacionResponse> {
    return this.http.post<NotificarFletamentoCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Cab_Cotizacion_OV_NotificarFletamento`, fd);
  }
  ConsultarCabCotizacionAprobacion(fd: ConsultarCabCotizacionAprobacionRequest): Observable<CabCotizacionAprobacionResponse> {
    return this.http.post<CabCotizacionAprobacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONSULTAR_Cab_Cotizacion_OV_Aprobacion`, fd);
  }

  AutentificarUsuario(fd: UserEntity): Observable<ResponseLogin> {
    return this.http.post<ResponseLogin>(`${environment.PATH_API}/autenticacion/login`, fd);
  }

  ActualizarEstadoCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse> {
    return this.http.post<GenerarCabCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ACTUALIZAR_Cab_Cotizacion_OV_Estado`, fd);
  }

  //#region API TT
  ListarOvApiTT(fd: ListarOvApiTTRequest): Observable<ListarOvApiTTResponse> {
    return this.http.post<ListarOvApiTTResponse>(`${environment.PATH_API}/comercial/ListarOvApiTT`, fd);
  }
  SolicitudAPI_MSC(fd: SolicitudAPI_MSCRequest): Observable<SolicitudAPI_MSCResponse> {
    return this.http.post<SolicitudAPI_MSCResponse>(`${environment.PATH_API}/integracion/trackAndTraceMSC`, fd);
  }
  SolicitudAPI_HAPAG(fd: SolicitudAPI_HAPAGRequest): Observable<SolicitudAPI_HAPAGResponse> {
    return this.http.post<SolicitudAPI_MSCResponse>(`${environment.PATH_API}/integracion/trackAndTraceHapag`, fd);
  }
  Actualiza_Campos_Viaje_Itenerario(fd: Actualiza_Campos_Viaje_ItenerarioRequest): Observable<any> {
    return this.http.post<any>(`${environment.PATH_API}/comercial/Actualiza_Campos_Viaje_Itenerario`, fd);
  }  
  SINC_API_TT_WEB(fd: SINC_API_TT_WEBRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/comercial/SINC_API_TT_WEB`, fd);
  }
  ManejoErrores_Flujo_Api(fd: ManejoErrores_Flujo_ApiRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/comercial/ManejoErrores_Flujo_Api`, fd);
  }
  ObtenerViajesAlAzar(fd: ObtenerViajesAlAzarRequest): Observable<ObtenerViajesAlAzarResponse> {
    return this.http.post<ObtenerViajesAlAzarResponse>(`${environment.PATH_API}/comercial/ObtenerViajesAlAzar`, fd);
  }
  ObtenerCantTramos(fd: ObtenerCantTramosRequest): Observable<ObtenerCantTramosResponse> {
    return this.http.post<ObtenerCantTramosResponse>(`${environment.PATH_API}/comercial/ObtenerCantTramos`, fd);
  }
  EnviarCorreoActualizacionTramos(fd: EnviarCorreoActualizacionTramosRequest): Observable<EnviarCorreoActualizacionTramosResponse> {
    return this.http.post<EnviarCorreoActualizacionTramosResponse>(`${environment.PATH_API}/comercial/EnviarCorreoActualizacionTramos`, fd);
  }
  EnviarCorreoErrorActualizarTramos(fd: EnviarCorreoErrorActualizarTramosRequest): Observable<EnviarCorreoErrorActualizarTramosResponse> {
    return this.http.post<EnviarCorreoErrorActualizarTramosResponse>(`${environment.PATH_API}/comercial/EnviarCorreoErrorActualizarTramos`, fd);
  }
  //#endregion
  ListarCuotaEjecutivo(fd: ListarCuotaEjecutivoRequest): Observable<ListarCuotaEjecutivoResponse> {
    return this.http.post<ListarCuotaEjecutivoResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarCuota`, fd);
  }
  ListarEjecutivosCuota(fd: ListarEjecutivosCuotaRequest): Observable<ListarEjecutivosCuotaResponse> {
    return this.http.post<ListarEjecutivosCuotaResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarEjecutivosCuota`, fd);
  }
  TransaccionEjecutivosCuota(fd: TransaccionEjecutivosCuotaRequest): Observable<TransaccionEjecutivosCuotaResponse> {
    return this.http.post<TransaccionEjecutivosCuotaResponse>(`${environment.PATH_API}/mantenimientocomercial/TransaccionCuota`, fd);
  }
  ImportarCuotasEjecutivo(fd: ImportarCuotasEjecutivoRequest): Observable<ResponseImportarCuotasEjecutivo> {
    return this.http.post<ResponseImportarCuotasEjecutivo>(`${environment.PATH_API}/mantenimientocomercial/ImportarCuotasEjecutivos`, fd);
  }

  ObtenerFormatosByCorreo(fd: ObtenerFormatosCorreos): Observable<PreVisualizarFormatosResponse> {
    return this.http.post<PreVisualizarFormatosResponse>(`${environment.PATH_API}/comercial/ObtenerFormatosByCorreo`, fd);
  }

  EnvioFormatosByCorreo(fd: FormatoEnvioCorreos): Observable<FormatoEnvioCorreosResponse> {
    return this.http.post<FormatoEnvioCorreosResponse>(`${environment.PATH_API}/comercial/EnvioFormatosByCorreo`, fd);
  }

  copiarOV(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse> {
    return this.http.post<GenerarCabCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_INSERTAR_Cab_Cotizacion_OV_Copiar`, fd);
  }

  ListarEntidades(fd: ListarEntidadesRequest): Observable<ListarEntidadesResponse> {
    return this.http.post<ListarEntidadesResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarEntidades`, fd);
  }
  ListarCuentasBancarias(fd: ListarCuentasBancariasRequest): Observable<ListarCuentasBancariasResponse> {
    return this.http.post<ListarCuentasBancariasResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarCuentasBancarias`, fd);
  }
  ListarLimiteCredito(fd: ListarLimiteCreditoRequest): Observable<ListarLimiteCreditoResponse> {
    return this.http.post<ListarLimiteCreditoResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarLimiteCredito`, fd);
  }
  ConsultarDireccionEntidad(fd: ConsultarDireccionEntidadRequest): Observable<ConsultarDireccionEntidadResponse> {
    return this.http.post<ConsultarDireccionEntidadResponse>(`${environment.PATH_API}/mantenimientocomercial/ConsultarDireccionEntidad`, fd);
  }
  ListarAuditoria(fd: ListarAuditoriaRequest): Observable<ListarAuditoriaResponse> {
    return this.http.post<ListarAuditoriaResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarAuditoriaEntidad`, fd);
  }
  ValidarEntidadSunat(fd: ValidarDocIdentidadRequest): Observable<ValidarDocIdentidadResponse> {
    return this.http.post<ValidarDocIdentidadResponse>(`${environment.PATH_API}/mantenimientocomercial/ValidarDocIdentidad`, fd);
  }
  ListarHistorialEntidad(fd: ListarHistorialRequest): Observable<ListarHistorialResponse> {
    return this.http.post<ListarHistorialResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarHistorialEntidad`, fd);
  }
  ListarArchivosEntidad(fd: ListarArchivoEntidadRequest): Observable<ListarArchivosEntidadResponse> {
    return this.http.post<ListarArchivosEntidadResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarArchivoEntidad`, fd);
  }
  TransaccionEntidadBloqueo(fd: TransaccionEntidadBloqueoRequest): Observable<TransaccionEntidadBloqueoResponse> {
    return this.http.post<TransaccionEntidadBloqueoResponse>(`${environment.PATH_API}/mantenimientocomercial/TransaccionEntidadBloqueo`, fd);
  }
  ListarUbigeos(fd: ListarUbigeosRequest): Observable<ListarUbigeosResponse> {
    return this.http.post<ListarUbigeosResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarUbigeo`, fd);
  }
  TransaccionEntidadRelacionados(fd: TransaccionEntidadRelacionadosRequest): Observable<TransaccionEntidadRelacionadosResponse> {
    return this.http.post<TransaccionEntidadRelacionadosResponse>(`${environment.PATH_API}/mantenimientocomercial/TransaccionEntidadRelacionados`, fd);
  }
  SincronizarEntidadSAP(fd:SincronizarEntidadSapRequest): Observable<SincronizarEntidadSapResponse> {
    return this.http.post<SincronizarEntidadSapResponse>(`${environment.PATH_API}/mantenimientocomercial/SincronizarEntidadSAP`, fd);
  }

  AnularOVcabCotizacion(fd: GenerarCabCotizacionRequest): Observable<GenerarCabCotizacionResponse> {
    return this.http.post<GenerarCabCotizacionResponse>(`${environment.PATH_API}/comercial/TCOM_SP_ANULAR_Cab_Cotizacion_OV`, fd);
  }

  ConcluirOVcabCotizacion(fd: ConcluirCabCotizacionRequest): Observable<ConcluirCabCotizacionErrorResponse> {
    return this.http.post<ConcluirCabCotizacionErrorResponse>(`${environment.PATH_API}/comercial/TCOM_SP_CONCLUIR_Cab_Cotizacion_OV`, fd);
  }

  ConsultarAcuerdoByEntidad(fd: ConsultarDireccionEntidadRequest): Observable<ConsultarEntidadAcuerdoResponse> {
    return this.http.post<ConsultarEntidadAcuerdoResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarAcuerdoByEntidad`, fd);
  }

  //#region SOBRESTADIA
  BuscarSobreestadia(fd: BuscarSobrestadiaRequest): Observable<BuscarSobrestadiaResponse> {
    return this.http.post<BuscarSobrestadiaResponse>(`${environment.PATH_API}/comercialvarios/BuscarSobreestadias`, fd);
  }
  BuscarContratoByTransportista(fd: TransportistaSobrestadiaRequest): Observable<BuscarContratoByTransportistaResponse> {
    return this.http.post<BuscarContratoByTransportistaResponse>(`${environment.PATH_API}/comercialvarios/BuscarTarifasContratoSegunTransportista`, fd);
  }
  TransaccionSobrestadia(fd:SobrestadiaTNSRequest):Observable<TransaccionSobrestadiaResponse> {
    return this.http.post<TransaccionSobrestadiaResponse>(`${environment.PATH_API}/comercialvarios/TransaccionSobrestadia`, fd);
  }
  //# endregion
  //#region GESTIÓN DE AVISOS
  BuscarNaveViajePorOrigen(fd: getNaveViajeByTransportistaRequest): Observable<ListarNaveViajeByTransportistaResponse> {
    return this.http.post<ListarNaveViajeByTransportistaResponse>(`${environment.PATH_API}/comercialvarios/BuscarNaveViajePorOrigen`, fd);
  }
  BuscarFormatosPorAviso(fd: BuscarFormatosPorAvisoRequest): Observable<BuscarFormatosPorAvisoResponse> {
    return this.http.post<BuscarFormatosPorAvisoResponse>(`${environment.PATH_API}/comercialvarios/BuscarFormatosPorAviso`, fd);
  }
  BuscarGestionDeAvisos(fd: BuscarGestionDeAvisosRequest): Observable<BuscarGestionDeAvisosResponse> {
    return this.http.post<BuscarGestionDeAvisosResponse>(`${environment.PATH_API}/comercialvarios/BuscarGestionDeAvisos`, fd);
  }
  GetFormatCorreosGestionDeAvisos(fd: GetFormatCorreosGestionDeAvisosRequest): Observable<GetFormatCorreosGestionDeAvisosResponse> {
    return this.http.post<GetFormatCorreosGestionDeAvisosResponse>(`${environment.PATH_API}/comercialvarios/ObtenerFormatosCorreosGestionDeAvisos`, fd);
  }
  //# endregion
  ConsultaIntegral(fd: ListarConsultaIntegralRequest): Observable<ListarConsultantegralResponse> {
    return this.http.post<ListarConsultantegralResponse>(`${environment.PATH_API}/reportescomercial/ListarConsultaIntegral`, fd);
  }
  ListarNave(fd: ListarNaveRequest): Observable<ListarNaveResponse> {
    return this.http.post<ListarNaveResponse>(`${environment.PATH_API}/mantenimientocomercial/ListarNave`, fd);
  }
  TransaccionNave(fd: TransaccionNaveRequest): Observable<TransaccionNaveResponse> {
    return this.http.post<TransaccionNaveResponse>(`${environment.PATH_API}/mantenimientocomercial/TransaccionNave`, fd);
  }
}
