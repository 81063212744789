import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { OperacionesRepository } from "../mantenimientos/domain/operaciones.repository";
import { Observable } from 'rxjs';
import { ListarLayoutRequest, ModificarClientesTarifasSLRequest } from '../mantenimientos/domain/operaciones-request';
import { ListarLayoutRespose, ListarClientesTarifasSLRespose, ModificarClientesTarifasSLResponse } from '../mantenimientos/domain/operaciones-response';
import { OperacionRepository } from '../repositorio/operacion.repository';
import { AsignarEventoPorOv, ConsultaAvisoLlegadaequest, ConsultaDesgloseViajeRequest, EnvioCorreoETAETDActualizado, ListaEntregaNave, listaNaves, MandarCorreoTodoDatos, ReporteTeusDetalladoRequest, ResumenEntregaNave, TransaccionConsultaNave } from '../operacion/operacion/consulta-nave/domain/consulta-naves-request';
import { ConsultaAvisoLlegadaResponse, ConsultaDesgloseViajeResponse, consultaEntregaNaveResponse, consultaNaveResponse, consultaResumenNaveResponse, GeneralResponse, ReporteTeusDetalladoResponse, ResponseEnvioCorreo, sp_Consulta_StatusAvisoLlegadaResponse } from '../operacion/operacion/consulta-nave/domain/consulta-naves-response';
import { environment } from 'src/environments/environment';
import { listaFletacionesRequest, listaVersioanacionesRequest } from '../operacion/reportes-operacion/domain/reportes-operaciones.request';
import { listaDetalleFletacionesResponse, listaDetalleVersionacionesResponse, listaFletacionesResponse, listaVersionacionesResponse } from '../operacion/reportes-operacion/domain/reportes-operaciones.response';
import { DireccionamientoCargaResponse, ImportarRepDevolucionCNTRResponse, ListaContenedorPorOVResponse, ListadoNaveViajeDetalladoResponse, ListaNaveViajeResponse, listarTodosOVResponse, paObtieneOperacionesLogisticasPorPreFacturarResponse, ReporteDevCNTRHPLResponse, ReporteDevCNTRMSCResponse, ResponseImportarEnviosIngresoCargonet, ResponseImportarReporteAduanet, ResponseImportarReporteHapagMaster, ResponseImportarReporteMaerksMaster, ResponseImportarReporteMSC, ResponseListarAvisosSobreestadia, ResponseListarVitacoraNave, ResponseReporteAduanet, ResponseReporteEnviosIngresoCargonet, ResponseReporteHapagMaster, ResponseReporteMaerksMaster, ResponseReporteSolicitudMSC, TransaccionesMontoSobreestadiaResponse } from '../operacion/operacion/control-ovs/domain/control-ovs.response';
import { CorreoAvisoSobrestadiaRequest, DireccionamientoCargaRequest, DireccionamientoGestionNavesRequest, ImportarRepDevolucionCNTRRequest, InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnRegRequest, ListaContenedorPorOVRequest, ListadoNaveViajeDetalladoRequest, ListaNaveViajeRequest, listarTodosOVRequest, opeActualizarDepositoEnDireccionamientoRequest, opeInsertServicesTOPRequest, opeUpdateHbls, opeUpdateNaveViajeRequest, paActualizaDocumentoVentas2Request, paObtieneOperacionesLogisticasPorPreFacturarRequest, ReporteDevCNTRRequest, RequestImportarEnviosIngresoCargonet, RequestImportarReporteAduanet, RequestImportarReporteHapagMaster, RequestImportarReporteMaerksMaster, RequestImportarReporteSolicitudMSC, RequestListarAvisosSobreestadia, RequestListarVitacoraNave, RequestReporteAduanet, RequestReporteEnviosIngresoCargonet, RequestReporteHapagMaster, RequestReporteMaerksMaster, RequestReporteSolicitudMSC, sp_Consulta_StatusAvisoLlegadaRequest, TransaccionesMontoSobreestadiaRequest, TransaccionFechaDevolucionRequest, UpdateFechaDireccionamientoRequest } from '../operacion/operacion/control-ovs/domain/control-ovs.request';
import { D_TablaRequest, ListarTarifasProveedorRequest, sp_TransaccionGuardarCostosTeusDetaladoRequest, TransaccionFlujoTarjaRequest, TransaccionTarifaProveedorRequest } from '../operacion/tarja/domain/tarja.request';
import { GeneralTransaccionResponse, ListarTarifasProveedorResponse } from '../operacion/tarja/domain/tarja.response';
import { ReporteCompOVRequest } from '../dashboard-comportamiento-ov/domain/request/dashboard-comportamiento-ov-request';
import { ReporteCompOVDetalleResponse, ReporteCompOVResumenResponse } from '../dashboard-comportamiento-ov/domain/response/dashboard-comportamiento-ov-response';
import { ReporteProfitNegativoRequest } from '../comercial/reportes/componentes/reporte-profit-negativo/domain/profit-negativo.request';
import { ReporteProfitNegDetalleResponse, ReporteProfitNegResumenEResponse, ReporteProfitNegResumenMResponse } from '../comercial/reportes/componentes/reporte-profit-negativo/domain/profit-negativo.response';
@Injectable({
  providedIn: 'root'
})
export class operacionService extends OperacionRepository {



  constructor(
    private readonly http: HttpClient
  ) {
    super();
  }

  listaNaves(fd: listaNaves): Observable<consultaNaveResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ConsultaNave`, fd);
  }
  listaEntregaNaves(fd: ListaEntregaNave): Observable<consultaEntregaNaveResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/AvanceEntregaNave`, fd);
  }
  listaResumenNaves(fd: ResumenEntregaNave): Observable<consultaResumenNaveResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/AvanceEntregaNave`, fd);
  }

  MandarCorreoTodoDatos(fd: EnvioCorreoETAETDActualizado): Observable<GeneralResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/DiseDeCorreo`, fd);
  }

  // listaNaveEntregada(fd: NaveEntregada): Observable<GeneralResponse> {
  //   return this.http.post<any>(`${environment.PATH_API}/operaciones/UpdateNaveEntregada`, fd);
  // }

  // listaFechaETAETD2(fd: FechaETAETD2): Observable<GeneralResponse> {
  //   return this.http.post<any>(`${environment.PATH_API}/operaciones/UpdateFechaETAETDActualizada`, fd);

  // }

  listaTransacionConsultaNave(fd: TransaccionConsultaNave): Observable<GeneralResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/TransaccionConsultaNave`, fd);

  }

  EnviarCorreo(fd: EnvioCorreoETAETDActualizado): Observable<ResponseEnvioCorreo> {
    return this.http.post<ResponseEnvioCorreo>(`${environment.PATH_API}/integracion/enviarCorreoApi`, fd);
  }

  override AsignarEventoPorOv(fd: AsignarEventoPorOv): Observable<GeneralResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/AsignarEventoPorOv`, fd);
  }




  listaFletaciones(fd: listaFletacionesRequest): Observable<listaFletacionesResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/IndicadoresOV`, fd);
  }
  listarDetalleFletaciones(fd: listaFletacionesRequest): Observable<listaDetalleFletacionesResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/IndicadoresOV`, fd);
  }
  listaVersionaciones(fd: listaVersioanacionesRequest): Observable<listaVersionacionesResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/IndicadoresOV`, fd);
  }
  listarDetalleVersionaciones(fd: listaVersioanacionesRequest): Observable<listaDetalleVersionacionesResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/IndicadoresOV`, fd);
  }
  listarTodosOV(fd: listarTodosOVRequest): Observable<listarTodosOVResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ListarTodosOV`, fd);
  }
  listarVitacoraNave(fd: RequestListarVitacoraNave): Observable<ResponseListarVitacoraNave> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ListaVitacoraNave`, fd);
  }
  reporteEnviosIngresoCargonet(fd: RequestReporteEnviosIngresoCargonet): Observable<ResponseReporteEnviosIngresoCargonet> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ReporteEnviosIngresoCargonet`, fd);
  }
  importarEnviosIngresoCargonet(fd: RequestImportarEnviosIngresoCargonet): Observable<ResponseImportarEnviosIngresoCargonet> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ImportarEnviosIngresoCargonet`, fd);
  }
  ConsultaDesglosePorViaje(fd: ConsultaDesgloseViajeRequest): Observable<ConsultaDesgloseViajeResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ConsultaDesglosePorViaje`, fd);
  }
  reporteAduanet(fd: RequestReporteAduanet): Observable<ResponseReporteAduanet> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ReporteAduanet`, fd);
  }
  override importarReporteAduanet(fd: RequestImportarReporteAduanet): Observable<ResponseImportarReporteAduanet> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ImportarAduanet`, fd);
  }
  ListarAvisosSobreestadia(fd: RequestListarAvisosSobreestadia): Observable<ResponseListarAvisosSobreestadia> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ListarAvisosSobreestadia`, fd);
  }

  opeUpdateNaveViaje(fd: opeUpdateNaveViajeRequest): Observable<GeneralResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/OpeUpdateNaveViaje`, fd);
  }
  transaccionesMontoSobreestadia(fd: TransaccionesMontoSobreestadiaRequest): Observable<TransaccionesMontoSobreestadiaResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/TransaccionesMontoSobreestadia`, fd);
  }

  opeUpdateHbls(fd: opeUpdateHbls): Observable<GeneralResponse> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/opeUpdateHbls`, fd);
  }
  reporteSolicitudMSC(fd: RequestReporteSolicitudMSC): Observable<ResponseReporteSolicitudMSC> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ReporteMSC`, fd);
  }
  importarreporteSolicitudMSC(fd: RequestImportarReporteSolicitudMSC): Observable<ResponseImportarReporteMSC> {
    return this.http.post<any>(`${environment.PATH_API}/operaciones/ImportarReporteMSC`, fd);
  }
  TransaccionFechaDevolucion(fd: TransaccionFechaDevolucionRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/operaciones/TransaccionFechaDevolucion`, fd);
  }
  DireccionamientoCarga(fd: DireccionamientoCargaRequest): Observable<DireccionamientoCargaResponse> {
    return this.http.post<DireccionamientoCargaResponse>(`${environment.PATH_API}/operaciones/ReporteDireccionamientoCarga`, fd);
  }
  ListaNaveViaje(fd: ListaNaveViajeRequest): Observable<ListaNaveViajeResponse> {
    return this.http.post<ListaNaveViajeResponse>(`${environment.PATH_API}/operaciones/ListaNaveViaje`, fd);
  }
  override ListaContenedorPorOV(fd: ListaContenedorPorOVRequest): Observable<ListaContenedorPorOVResponse> {
    return this.http.post<ListaContenedorPorOVResponse>(`${environment.PATH_API}/operaciones/ListaContenedorOV`, fd);
  }

  ListadoNaveViajeDetallado(fd: ListadoNaveViajeDetalladoRequest): Observable<ListadoNaveViajeDetalladoResponse> {
    return this.http.post<ListadoNaveViajeDetalladoResponse>(`${environment.PATH_API}/operaciones/ListadoNaveViajeDetallado`, fd);
  }

  override UpdateFechaDireccionamiento(fd: UpdateFechaDireccionamientoRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/operaciones/UpdateFechaDireccionamiento`, fd);
  }

  paActualizaDocumentoVentas2(fd: paActualizaDocumentoVentas2Request): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/operaciones/paActualizaDocumentoVentas2`, fd);
  }


  paObtieneOperacionesLogisticasPorPreFacturar(fd: paObtieneOperacionesLogisticasPorPreFacturarRequest): Observable<paObtieneOperacionesLogisticasPorPreFacturarResponse> {
    return this.http.post<paObtieneOperacionesLogisticasPorPreFacturarResponse>(`${environment.PATH_API}/operaciones/paObtieneOperacionesLogisticasPorPreFacturar`, fd);
  }

  ConsultaAvisoLlegada(fd: ConsultaAvisoLlegadaequest): Observable<ConsultaAvisoLlegadaResponse> {
    return this.http.post<ConsultaAvisoLlegadaResponse>(`${environment.PATH_API}/operaciones/ConsultaAvisoLlegada`, fd);
  }

  //#region Tarja

  ReporteTeusDetallado(request: ReporteTeusDetalladoRequest): Observable<ReporteTeusDetalladoResponse> {
    return this.http.post<ReporteTeusDetalladoResponse>(`${environment.PATH_API}/operaciones/ReporteTeusDetallado`, request);
  }

  TransaccionTarifaProveedor(request: TransaccionTarifaProveedorRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/Tarja/TranTarifaProveedor`, request);
  }

  override ListarTarifasProveedor(request: ListarTarifasProveedorRequest): Observable<ListarTarifasProveedorResponse> {
    return this.http.post<ListarTarifasProveedorResponse>(`${environment.PATH_API}/operaciones/Tarja/ListarTarifasProveedor`, request);
  }

  TransaccionFlujoTarja(request: TransaccionFlujoTarjaRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/Tarja/TranFlujoTarja`, request);
  }
  //#endregion



  //Daniel
  Tabla(request: D_TablaRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/Tabla`, request);
  }

  override listaReporteComportamientoOV(request: ReporteCompOVRequest): Observable<ReporteCompOVResumenResponse> {
    return this.http.post<ReporteCompOVResumenResponse>(`${environment.PATH_API}/operaciones/IndicadoresComportamientoOV`, request);
  }
  override listaReporteComportamientoOVDetalle(request: ReporteCompOVRequest): Observable<ReporteCompOVDetalleResponse> {
    return this.http.post<ReporteCompOVDetalleResponse>(`${environment.PATH_API}/operaciones/IndicadoresComportamientoOV`, request);
  }
  InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnReg(fd: InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnRegRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/operaciones/InsertVEN_DOCVSI_UnRegANDVEN_DDOVSI_UnReg`, fd);
  }
  reporteHapagMaster(request: RequestReporteHapagMaster): Observable<ResponseReporteHapagMaster> {
    return this.http.post<ResponseReporteHapagMaster>(`${environment.PATH_API}/operaciones/ReporteDatosHapagMaster`, request);
  }
  reporteMaerksMaster(request: RequestReporteMaerksMaster): Observable<ResponseReporteMaerksMaster> {
    return this.http.post<ResponseReporteMaerksMaster>(`${environment.PATH_API}/operaciones/ReporteDatosMaerskMaster`, request);
  }
  importarDatosHapagMaster(request: RequestImportarReporteHapagMaster): Observable<ResponseImportarReporteHapagMaster> {
    return this.http.post<ResponseImportarReporteHapagMaster>(`${environment.PATH_API}/operaciones/ImportarDatosHapagMaster`, request);
  }
  importarDatosMaerksMaster(request: RequestImportarReporteMaerksMaster): Observable<ResponseImportarReporteMaerksMaster> {
    return this.http.post<ResponseImportarReporteMaerksMaster>(`${environment.PATH_API}/operaciones/ImportarDatosMaerskMaster`, request);
  }
  opeActualizarDepositoEnDireccionamiento(fd: opeActualizarDepositoEnDireccionamientoRequest): Observable<GeneralResponse> {
    return this.http.post<GeneralResponse>(`${environment.PATH_API}/operaciones/opeActualizarDepositoEnDireccionamiento`, fd);
  }

  opeInsertServicesTOP(fd: opeInsertServicesTOPRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/serviciosLocales/InsertServiceTop`, fd);
  }

  sp_Consulta_StatusAvisoLlegada(fd: sp_Consulta_StatusAvisoLlegadaRequest): Observable<sp_Consulta_StatusAvisoLlegadaResponse> {
    return this.http.post<sp_Consulta_StatusAvisoLlegadaResponse>(`${environment.PATH_API}/operaciones/sp_Consulta_StatusAvisoLlegada`, fd);

  }

  sp_TransaccionGuardarCostosTeusDetalado(fd: sp_TransaccionGuardarCostosTeusDetaladoRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/sp_TransaccionGuardarCostosTeusDetalado`, fd);
  }

  reporteProfitNegativoDetalle(fd: ReporteProfitNegativoRequest): Observable<ReporteProfitNegDetalleResponse> {
    return this.http.post<ReporteProfitNegDetalleResponse>(`${environment.PATH_API}/reporteG/ReporteProfitNegDetalle`, fd);
  }
  reporteProfitNegativoResumenE(fd: ReporteProfitNegativoRequest): Observable<ReporteProfitNegResumenEResponse> {
    return this.http.post<ReporteProfitNegResumenEResponse>(`${environment.PATH_API}/reporteG/ReporteProfitNegResumenE`, fd);
  }
  reporteProfitNegativoResumenM(fd: ReporteProfitNegativoRequest): Observable<ReporteProfitNegResumenMResponse> {
    return this.http.post<ReporteProfitNegResumenMResponse>(`${environment.PATH_API}/reporteG/ReporteProfitNegResumenM`, fd);
  }
  DireccionamientoGestionNaves(fd: DireccionamientoGestionNavesRequest): Observable<GeneralTransaccionResponse> {
    return this.http.post<GeneralTransaccionResponse>(`${environment.PATH_API}/operaciones/RegDireccionamientoGestionNaves`, fd);
  }

  CorreoAvisoSobrestadia(request: CorreoAvisoSobrestadiaRequest): Observable<boolean> {
    return this.http.post<boolean>(`${environment.PATH_API}/operaciones/CorreoAvisoSobrestadia`, request);
  }

  ReporteDevCNTRMSC(request: ReporteDevCNTRRequest): Observable<ReporteDevCNTRMSCResponse> {
    return this.http.post<ReporteDevCNTRMSCResponse>(`${environment.PATH_API}/operaciones/ReporteDevolucionCNTR`, request);
  }

  ReporteDevCNTRHPL(request: ReporteDevCNTRRequest): Observable<ReporteDevCNTRHPLResponse> {
    return this.http.post<ReporteDevCNTRHPLResponse>(`${environment.PATH_API}/operaciones/ReporteDevolucionCNTR`, request);
  }

  ImportarRepDevolucionCNTR(request: ImportarRepDevolucionCNTRRequest): Observable<ImportarRepDevolucionCNTRResponse> {
    return this.http.post<ImportarRepDevolucionCNTRResponse>(`${environment.PATH_API}/operaciones/ImportarRepDevolucion`, request);
  }
}