<div fxLayoutGap="20px" class="container_botones">

  <!-- Guardar -->
  <button mat-stroked-button class="button" (click)="guardaRequisision('I')">Guardar
    <mat-icon>save</mat-icon>
  </button>

  <!-- Salir -->
  <button mat-stroked-button class="button" (click)="Salir('')">Salir
    <mat-icon>exit_to_app</mat-icon>
  </button>

</div>

<!-- APARTADO DE REQUERIMIENTO -->
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      Requerimiento
    </ng-template>

    <div #contentToConvert fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center"
      style="margin-top: 10px;">
      <div fxFlex="100" style="padding: 0px;">
        <form [formGroup]="groupRequerimiento" class="formularioStyle">

          <div class="label">
            <!--Nro Requerimiento-->
            <mat-form-field class="example-full-width-alterno3" appearance="outline">
              <input matInput type="text" placeholder="Nro.Requerimiento" formControlName="nrorequerimiento"
                [readonly]="pricing">
            </mat-form-field>

            <!--Estado-->
            <mat-form-field *ngIf="apareceestado"  class="example-full-width-alterno4" appearance="outline">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="estado">
                <mat-option *ngFor="let reg of listEstado" [disabled]="pricing"
                  [value]="reg.codigo">{{reg.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="label">
            <!--RAZON SOCIAL-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <input matInput type="text" formControlName="razonSocial" matInput [matAutocomplete]="autoRazonSocial"
                (input)="cargaRucInterno('I')" [readonly]="pricing">
              <mat-autocomplete #autoRazonSocial="matAutocomplete" (optionSelected)="cargaRucInterno('I')">
                <mat-option *ngFor="let option of limitedOptions" [value]="option" [disabled]="pricing">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <mat-label>Razon Social</mat-label>
              <mat-icon matSuffix (click)="limpiar('razonSocial')">close</mat-icon>
              <mat-error class="custom-error">Ingrese razon social</mat-error>
            </mat-form-field>
            <!--RUC-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno4">
              <input matInput type="text" formControlName="ruc" [readonly]="pricing">
              <mat-label>RUC</mat-label>
              <mat-icon matSuffix (click)="cargaSunat()">search</mat-icon>
              <mat-icon matSuffix (click)="limpiar('ruc')">close</mat-icon>
              <mat-error class="custom-error">Ingrese RUC</mat-error>
            </mat-form-field>
          </div>

          <div class="label" style="display: flex;">

            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Contacto</mat-label>
              <mat-select formControlName="contacto">
                <mat-option *ngFor="let contacto of listContacto"
                  [value]="contacto.entC_CodContacto">{{contacto.nombre}} {{contacto.apellidos}}</mat-option>
              </mat-select>
            </mat-form-field>

            <div style="display: flex;gap: 20px;align-items: center;height: 50px;">
              <mat-icon matSuffix (click)="openModal(contactoComponent, 'contacto')" *ngIf="listaContactoJ.length === 0"
                style="cursor: pointer;">people</mat-icon>
              <span>Añadir contacto</span>
            </div>

          </div>

          <div class="label">

            <!-- REGIMEN -->

            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Regimen</mat-label>
              <mat-select formControlName="regimen" #regimen>
                <mat-option *ngFor="let reg of listReg" [value]="reg.codigo"
                  [disabled]="pricing">{{reg.nombre}}</mat-option>
              </mat-select>
              <mat-error>Ingrese regimen</mat-error>
            </mat-form-field>




            <!-- VIA -->
            <mat-form-field class="list" class="example-full-width-alterno4" appearance="outline">
              <mat-label>Via</mat-label>
              <mat-select formControlName="via" #via>
                <mat-option *ngFor="let via of listVia" [value]="via.codigo"
                  [disabled]="pricing">{{via.nombre}}</mat-option>
              </mat-select>
              <mat-error class="custom-error">Ingrese via</mat-error>
            </mat-form-field>

          </div>

          <div class="label">
            <!-- Puerto Origen -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <mat-label>Puerto Origen</mat-label>
              <input matInput type="text" placeholder="Puerto Origen" formControlName="ptoOrigen"
                [matAutocomplete]="ptoO" (input)="cargaPuerto('ptoOrigen')" value={{nomPtoOrigen}} [readonly]="pricing">
              <mat-autocomplete #ptoO="matAutocomplete" (optionSelected)="selectedPtoOrigen($event)">
                <mat-option *ngFor="let option of listPrtoO" [value]="option.nombre">{{ option.nombre}}</mat-option>
              </mat-autocomplete>
              <mat-error class="custom-error">Ingrese puerto origen</mat-error>
            </mat-form-field>

            <!-- Puerto Destino -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno4">
              <mat-label>Puerto Destino</mat-label>
              <input matInput type="text" placeholder="Puerto Destino" formControlName="ptoDestino"
                [matAutocomplete]="ptoD" (input)="cargaPuerto('ptoDestino')" value={{nomPtoDestino}}
                [readonly]="pricing">
              <mat-autocomplete #ptoD="matAutocomplete" (optionSelected)="selectedPtoDestino($event)">
                <mat-option *ngFor="let option of listPrtoD" [value]="option.nombre">{{option.nombre}}</mat-option>
              </mat-autocomplete>
              <mat-error class="custom-error">Ingrese puerto destino</mat-error>
            </mat-form-field>
          </div>

          <div class="label">
            <!-- Tipo de carga -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <mat-label>Tipo Carga</mat-label>
              <mat-select formControlName="tCarga" [disabled]="pricing">
                <!-- <mat-option disabled value="usuario" [disabled]="pricing">Selecciona un Tipo Carga</mat-option> -->
                <mat-option value="1" [disabled]="pricing">Peligrosa</mat-option>
                <mat-option value="2" [disabled]="pricing">General</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Tipo de modalidad -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno4">
              <mat-label>Modalidad</mat-label>
              <mat-select formControlName="modalidad">
                <mat-option *ngFor="let condicion of listCondicion" [value]="condicion.codigo"
                  [disabled]="pricing">{{condicion.nombre}}</mat-option>
              </mat-select>
              <mat-error class="custom-error">Ingrese modalidad</mat-error>
            </mat-form-field>

          </div>

          <div class="label">

            <!--IMO-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <mat-label>IMO</mat-label>
              <mat-select formControlName="imo">
                <mat-option *ngFor="let reg of listImo" [value]="reg.codigo"
                  [disabled]="pricing">{{reg.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- UN -->
            <mat-form-field class="example-full-width-alterno4" appearance="outline">
              <mat-label>UN</mat-label>
              <input matInput type="text" formControlName="un" min="0" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('un')">close</mat-icon>
            </mat-form-field>

            <!--  <button mat-raised-button class="button1" (click)="abrirComponenteEnNuevaPestana()">Medidas</button>-->
          </div>

          <div class="label">

            <!-- Fecha de carga -->
            <mat-form-field class="example-full-width-alterno3" appearance="outline">
              <mat-label>Fecha Carga</mat-label>
              <input matInput formControlName="fechaCarga" [matDatepicker]="picker1" autocomplete="off"
                [readonly]="pricing" [disabled]="pricing" readonly [disabled]="true">
              <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="pricing"></mat-datepicker-toggle>
              <mat-datepicker #picker1 color="primary"></mat-datepicker>
              <!-- {{ groupRequerimiento.get('fechaCarga').value | date: 'dd/MM/yyyy' }} -->
            </mat-form-field>

            <!-- Fecha de vigencia -->
            <mat-form-field class="example-full-width-alterno4" appearance="outline">
              <mat-label>Fecha vigencia</mat-label>
              <input matInput formControlName="fechaVigencia" [matDatepicker]="picker2" autocomplete="off" readonly
                [disabled]="true" [readonly]="pricing" [disabled]="pricing">
              <mat-datepicker-toggle matSuffix [for]="picker2" [disabled]="pricing"></mat-datepicker-toggle>
              <mat-datepicker #picker2 color="primary"></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Direccion de entrega -->
          <div class="label">
            <mat-form-field class="example-full-width-alterno3" appearance="outline">
              <mat-label>Dir.Entrega</mat-label>
              <input matInput type="text" formControlName="dirEntrega" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('dirEntrega')">close</mat-icon>
            </mat-form-field>

            <!-- Direccion de recojo -->
            <mat-form-field class="example-full-width-alterno4" appearance="outline">
              <mat-label>Dir.Recojo</mat-label>
              <input matInput type="text" formControlName="dirRecojo" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('dirRecojo')">close</mat-icon>
            </mat-form-field>

          </div>

          <div class="label">

            <!--Mercaderia-->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno3">
              <mat-label>Mercaderia</mat-label>
              <input matInput type="text" formControlName="mercaderia" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('mercaderia')">close</mat-icon>
              <mat-error class="custom-error">Ingrese Mercaderia</mat-error>
            </mat-form-field>

            <!-- Target -->
            <mat-form-field class="example-full-width-alterno" appearance="outline">
              <mat-label>Target</mat-label>
              <input matInput type="number" formControlName="tarjet" min="0" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('tarjet')">close</mat-icon>
            </mat-form-field>

            <!-- Incoterm -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno">
              <mat-label>Incoterm</mat-label>
              <mat-select formControlName="incoter">
                <mat-option *ngFor="let incoter of listIncoter" [value]="incoter.codigo"
                  [disabled]="pricing">{{incoter.nombre}}</mat-option>
              </mat-select>
              <mat-error class="custom-error">Ingrese incoterm</mat-error>
            </mat-form-field>

            <!-- Dias libres requeridos -->
            <mat-form-field class="example-full-width-alterno" appearance="outline">
              <mat-label>Dias Libres</mat-label>
              <input matInput type="number" formControlName="diasLibres" min="0" [readonly]="pricing">
              <mat-icon matSuffix (click)="limpiar('diasLibres')">close</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayoutGap="20px" class="label">
            <!-- Observaciones -->
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Observaciones</mat-label>
              <textarea matInput formControlName="observacionEjec" [readonly]="pricing"></textarea>
              <mat-icon matSuffix (click)="limpiar('observacionEjec')">close</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayoutGap="20px" class="label">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Datos Adicionales para el Agente</mat-label>
              <textarea matInput type="text" formControlName="datosAdicionales" [readonly]="pricing"></textarea>
              <mat-icon matSuffix (click)="limpiar('datosAdicionales')">close</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayoutGap="20px" class="label">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Datos Adicionales para el Agente (ingles)</mat-label>
              <textarea matInput formControlName="observacionIngles" [readonly]="pricing"></textarea>
              <mat-icon matSuffix (click)="limpiar('observacionIngles')">close</mat-icon>
            </mat-form-field>
          </div>



          <!-- <div fxLayoutGap="20px" class="label">
                          <mat-form-field class="list" appearance="outline" class="example-full-width">
                              <mat-label>Condiciones</mat-label>
                              <input matInput type="text" placeholder="condiciones" formControlName="condiciones">
                            </mat-form-field>
                        </div> -->
        </form>

        <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="40px">
          <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;"></div>
        </div>

        <div style="height: 50px;"></div>

      </div>
    </div>
  </mat-tab>

  <!-- APARTADO DE CONTENEDOR -->
  <mat-tab>
    <ng-template mat-tab-label>
      Contenedor
    </ng-template>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 10px;">
      <div fxFlex="100" style="padding: 2px;">

        <form [formGroup]="groupContenedor">

          <div fxLayoutGap="20px" class="label">

            <!--CONTENEDOR-->
            <mat-form-field class="list" class="example-full-width-alterno3" appearance="outline">
              <mat-label>Contenedor</mat-label>
              <mat-select formControlName="contenedor">
                <mat-option *ngFor="let contenedor of listContenedor"
                  [value]="contenedor.codigo">{{contenedor.nombre}}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Cantidad -->
            <mat-form-field class="list" appearance="outline" class="example-full-width-alterno2">
              <input matInput type="number" placeholder="Cantidad" formControlName="cantidad" [readonly]="pricing"
                min="0">
            </mat-form-field>

            <!-- Agregar -->
            <button mat-raised-button class="buttonadd" matTooltip="Cargar Tarifa" type="search"
              (click)="agregaItemContenedor()" [disabled]="pricing">Agregar
              <mat-icon>library_add</mat-icon>
            </button>


            <!-- Actualizar 
            <button mat-raised-button class="buttonadd" matTooltip="Cargar Tarifa" type="search"
              (click)="ActualizaContenedor(rowUpdateContenedor)" [disabled]="pricing"
              *ngIf="btnActualizaContenedor">Actualiza
              <mat-icon>library_add</mat-icon>
            </button>-->

          </div>

        </form>
        <!--CONTAINER TABLE-->
        <app-table [dataTable]="dataContenedor" [metadataTable]="metaDataTableContenedorOnly">
          <ng-container matColumnDef="actions">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row, let index = index" class="cell">
              <button mat-button type="button" class="hover-icon-button" (click)="cargaUpdateContenedor(row, index)">
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>

              <button mat-button type="button" (click)="deletecontenedorejecutivo(row)" class="hover-icon-button">
                <mat-icon class="delete-icon">delete</mat-icon>
              </button>

            </mat-cell>
          </ng-container>
        </app-table>
        <div>
          <!--   <button (click)="guardaRequisision('I')">GUARDAR</button>-->
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- APARTADO DE BULTOS -->
  <mat-tab>
    <ng-template mat-tab-label>
      Bultos
    </ng-template>
    <!--PRUEBA DE PRECARGA DE IMAGEN-->
    <!-- <input type="file" (change)="onFileSelected($event)" accept="image/*">
      <button (click)="clearImage()">Eliminar Imagen</button>
      <img *ngIf="selectedFile" [src]="getObjectURL(selectedFile)" [style.width.px]="imageWidth" [style.height.px]="imageHeight"> -->
    <form class="container_general" [formGroup]="group">
      <!--Mercaderia-->
      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Largo</mat-label>
        <input (input)="inputcubicaje()" matInput type="number" formControlName="largo" min="0" [readonly]="pricing">
      </mat-form-field>
      <!--Mercaderia  (input)="cargaRucInterno('I')"-->
      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Ancho</mat-label>
        <input (input)="inputcubicaje()" matInput type="number" formControlName="ancho" min="0" [readonly]="pricing">
      </mat-form-field>
      <!--Mercaderia-->
      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Alto</mat-label>
        <input (input)="inputcubicaje()" matInput type="number" formControlName="alto" min="0" [readonly]="pricing">
      </mat-form-field>
      <!--Mercaderia-->
      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Peso</mat-label>
        <input matInput type="number" formControlName="peso" min="0" [readonly]="pricing">

      </mat-form-field>
      <mat-form-field class="example-full-width-alterno5" appearance="outline">
        <mat-label>UM</mat-label>
        <mat-select formControlName="umpeso">
          <mat-option *ngFor="let uMedida of listaUnidadMedida" [value]="uMedida.codigo">{{uMedida.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>
      <!--Mercaderia-->
      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Volumen</mat-label>
        <input matInput type="number" formControlName="volumen" min="0" [readonly]="pricing">
      </mat-form-field>

      <mat-form-field class="example-full-width-alterno5" appearance="outline">
        <mat-label>UM</mat-label>
        <mat-select formControlName="umvolumen">
          <mat-option *ngFor="let uMedida of listaUnidadMedida" [value]="uMedida.codigo">{{uMedida.nombre}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="list" appearance="outline" class="example-full-width-alterno5">
        <mat-label>Cant.Bultos</mat-label>
        <input matInput type="number" formControlName="cantBultos" min="0" [readonly]="pricing">
      </mat-form-field>
      <button (click)="agregarItemBultos()" *ngIf="!editaBul" [disabled]="pricing" class="buttonadd"><mat-icon
          class="mat-18">add</mat-icon></button>
      <button (click)="actualizaBultos()" *ngIf="editaBul" class="buttonadd"><mat-icon>update</mat-icon></button>
    </form>

    <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
      <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;">
        <!--CONTAINER TABLE-->
        <app-table [dataTable]="dataBultos" [metadataTable]="metaDataTableBultos">
          <ng-container matColumnDef="actions">
            <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row let i" class="cell">
              <button mat-button type="button" class="hover-icon-button" (click)="editaBultos(row,i)"
                [disabled]="pricing">
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>
              <button mat-button type="button" class="hover-icon-button" (click)="deletebultos(row)"
                [disabled]="pricing">
                <mat-icon class="delete-icon">delete</mat-icon></button>
            </mat-cell>
          </ng-container>
        </app-table>
      </div>
    </div>

  </mat-tab>
  <mat-tab class="pricing">
    <!-- <mat-tab class="pricing" *ngIf="pricing" > -->
    <ng-template mat-tab-label>
      Pricing {{titulotabpricing}}
    </ng-template>
    <form [formGroup]="groupPricing" class="contenedorPricing">
      <div class="label" fxLayoutGap="20px" style="padding-top: 10px;">
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="Sedebever">
          <mat-label>Transportista(Coloader)</mat-label>
          <input type="string" matInput formControlName="agente" [matAutocomplete]="autoAgente"
            [(ngModel)]="textoFiltroAgente">
          <mat-autocomplete #autoAgente="matAutocomplete" (optionSelected)="agenteSelecciona($event)">
            <ng-container *ngFor="let option of listAgente">
              <mat-option [value]="option.nombre"
                *ngIf="option.nombre?.toLowerCase().includes(textoFiltroAgente?.toLowerCase())">
                {{ option.nombre}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Agente</mat-label>
          <input type="string" matInput formControlName="transportistaP" [matAutocomplete]="autoTransportista"
            [(ngModel)]="textoFiltroTranportista">
          <mat-autocomplete #autoTransportista="matAutocomplete" (optionSelected)="transportistaPSelecciona($event)">
            <ng-container *ngFor="let option of listTransportistaP">
              <mat-option [value]="option.nombre"
                *ngIf="option.nombre?.toLowerCase().includes(textoFiltroTranportista?.toLowerCase())">
                {{ option.nombre}}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="label" fxLayoutGap="20px">
        <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="Sedebever">
          <mat-label>Tiempo de Transito</mat-label>
          <input type="number" matInput formControlName="tiempoTransito">
        </mat-form-field>

        <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="Sedebever">
          <mat-label>Unidad Medida</mat-label>
          <mat-select formControlName="unidadMedida">
            <mat-option *ngFor="let uMedida of listUnidadMedida"
              [value]="uMedida.codigo">{{uMedida.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--FALTAN LAS MONEDAS-->
      <div class="label" fxLayoutGap="20px">
        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Moneda</mat-label>
          <mat-select formControlName="moneda">
            <mat-option *ngFor="let moneda of listaMoneda" [value]="moneda.codigo">{{moneda.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="label" fxLayoutGap="20px" *ngIf="false">
        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Flete Monto</mat-label>
          <input type="text" matInput formControlName="fleteMonto">
        </mat-form-field>

        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Flete Costo</mat-label>
          <input type="text" matInput formControlName="fleteCosto">
        </mat-form-field>
      </div>

      <div class="label" fxLayoutGap="20px" *ngIf="!SedebeverAereo">

        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Dias Libres Confirmados</mat-label>
          <input type="number" matInput formControlName="diasLibres" min="0">
        </mat-form-field>

        <!--
            <mat-form-field  class="example-full-width-alterno3" appearance="outline">
              <mat-label>Dias Disponibles</mat-label>
              <input type="number" matInput formControlName="diasDisponibles">
            </mat-form-field> -->
      </div>

      <div class="label" fxLayoutGap="20px" *ngIf="Sedebever">
        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Vigencia desde</mat-label>
          <input matInput formControlName="fechaVigenciaIni" [matDatepicker]="picker3" autocomplete="off"
            placeholder="Fecha" (ngModelChange)="validarFechas()" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-datepicker #picker3 color="primary"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Vigencia hasta</mat-label>
          <input matInput formControlName="fechaVigenciaFin" [matDatepicker]="picker4" autocomplete="off"
            placeholder="Fecha" (ngModelChange)="validarFechas()" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
          <mat-datepicker #picker4 color="primary"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="label" fxLayoutGap="20px">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Observaciones</mat-label>
          <input type="text" matInput formControlName="observaciones">
        </mat-form-field>
      </div>
      <div class="label" fxLayoutGap="20px">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Observaciones Ejecutivos</mat-label>
          <input type="text" matInput formControlName="observacionesEjecutivosPricing" [readonly]="true">
        </mat-form-field>
      </div>
      <div class="label" fxLayoutGap="20px">
        <mat-form-field class="example-full-width-alterno3" appearance="outline">
          <mat-label>Dias Disponibles Proforma</mat-label>
          <input type="number" matInput formControlName="diasDisponibles" min="0">
        </mat-form-field>

        <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="Sedebever">
          <mat-label>Almacen</mat-label>
          <input type="string" matInput formControlName="almacen" [matAutocomplete]="autoAlmacen"
            [(ngModel)]="textoFiltroAlmacen1" (input)="cargaAlmacenPricing('I')">
          <mat-autocomplete #autoAlmacen="matAutocomplete" (optionSelected)="opcionSeleccionadaAlmacen($event)">
            <ng-container *ngFor="let option of listAlmacen">
              <mat-option [value]="option.nombre"
                *ngIf="option.nombre?.toLowerCase().includes(textoFiltroAlmacen1?.toLowerCase())">
                <!--*ngIf="option.descripcionTransportista.toLowerCase().includes(textoFiltro.toLowerCase())">-->
                {{ option.nombre }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="SedebeverAereo">
          <mat-label>Peso Cargable</mat-label>
          <input type="number" matInput formControlName="pesoRecurrente">
        </mat-form-field>
      </div>

      <div>
        <div *ngIf="!condicionMuestra">
          <div class="section-title">
            <h1>Contenedor</h1>
          </div>
          <div class="formularioStyle">
            <form class="label" [formGroup]="tabContenedor" (keydown.enter)="$event.preventDefault()">
              <mat-form-field class="example-full-width-alterno3" appearance="outline">
                <mat-label>Transportista</mat-label>
                <input type="text" matInput formControlName="transportistaCont" [matAutocomplete]="autoTransportista"
                  [(ngModel)]="textoFiltro">
                <mat-autocomplete #autoTransportista="matAutocomplete" (optionSelected)="opcionSeleccionada($event)">
                  <ng-container *ngFor="let option of listaTodosTransportistas">
                    <mat-option [value]="option.descripcionTransportista"
                      *ngIf="option.descripcionTransportista?.toLowerCase().includes(textoFiltro?.toLowerCase())">
                      {{ option.descripcionTransportista }}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno4" appearance="outline">
                <mat-label>Paquetes</mat-label>
                <mat-select formControlName="paquetes">
                  <mat-option *ngFor="let itempaquetes of listpaquetes"
                    [value]="itempaquetes.codigo">{{itempaquetes.nombre}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno3" appearance="outline">
                <mat-label>Costo</mat-label>
                <input type="number" matInput formControlName="costo" min="0">
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno4" appearance="outline">
                <mat-label>Venta</mat-label>
                <input type="number" matInput formControlName="valorCont" min="0">
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno3" appearance="outline">
                <mat-label>Fecha Inicio</mat-label>
                <input matInput formControlName="fechaVigenciaIni" [matDatepicker]="picker3" autocomplete="off"
                  (ngModelChange)="validarFechas()" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 color="primary"></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno4" appearance="outline">
                <mat-label>Fecha Fin</mat-label>
                <input matInput formControlName="fechaVigenciaFin" [matDatepicker]="picker4" autocomplete="off"
                  (ngModelChange)="validarFechas()" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker4"
                  (dateChange)="onDateChangeDesde($event,'fechaVigenciaFin')"></mat-datepicker-toggle>
                <mat-datepicker #picker4 color="primary"></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="example-full-width-alterno3" appearance="outline">
                <mat-label>Dias libres</mat-label>
                <input type="number" matInput formControlName="diasLibresCont" min="0">
              </mat-form-field>

            </form>
          </div>

          <button *ngIf="pricing" (click)="AgregarItem('Contenedor')" class="custom-button">ADD</button>

          <!--     <button (click)="agregarnewitem()" class="custom-button">Nueva Fila</button>-->

          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
            <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;">
              <!--CONTAINER TABLE-->
              <app-table [dataTable]="dataContenedor" [metadataTable]="metaDataTableContenedor">
                <ng-container matColumnDef="actions">
                  <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell">
                    <mat-checkbox (change)="selectAll($event)"></mat-checkbox>
                  </mat-header-cell>
                  <mat-cell mat-cell *matCellDef="let row" class="cell">
                    <mat-checkbox [(ngModel)]="row.dreQ_Seleccionada"
                      (change)="onRowSelect($event, row)"></mat-checkbox>
                    <button mat-button type="button" (click)='deletetarifa (row)'>
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-button type="button" class="hover-icon-button" (click)="editaritemcontenedor(row)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>
              </app-table>
            </div>
          </div>
        </div>
        <!---------------------------------------------------------------------------------------------------------------------->
        <div class="section-title">
          <h1>Costo de servicio</h1>
        </div>
        <form class="label" [formGroup]="tabCosto" (keydown.enter)="$event.preventDefault()">
          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Concepto</mat-label>
            <mat-select formControlName="conceptoCos">
              <mat-option *ngFor="let concepto of listCostoServicioConcepto"
                [value]="concepto.codigo">{{concepto.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline">
            <mat-label>Base</mat-label>
            <mat-select formControlName="baseCos">
              <mat-option *ngFor="let uMedida of listUnidadMedida"
                [value]="uMedida.codigo">{{uMedida.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Costo</mat-label>
            <input type="number" min="0" matInput formControlName="costo">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline">
            <mat-label>Venta</mat-label>
            <input type="number" min="0" matInput formControlName="montoCos">
          </mat-form-field>



          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Notas</mat-label> <!--esto remplaza a aplicaciones que ya no se usa-->
            <input type="text" matInput formControlName="aplicacionCos">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline" *ngIf="false">
            <mat-label>Detalles</mat-label>
            <input type="text" matInput formControlName="detalleCos">
          </mat-form-field>


          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="false">
            <mat-label>TDI</mat-label>
            <input type="number" matInput formControlName="tdi">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline" *ngIf="false">
            <mat-label>Carga</mat-label>
            <input type="number" matInput formControlName="carga">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="false">
            <mat-label>Almacen Monto</mat-label>
            <input type="number" matInput formControlName="almacenMonto">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline" *ngIf="SedebeverAereo">
            <mat-label>Aereolinea</mat-label>
            <input type="string" matInput formControlName="aereolineaCosto" [matAutocomplete]="autoAerolinea"
              [(ngModel)]="textoFiltro">
            <mat-autocomplete #autoAerolinea="matAutocomplete"
              (optionSelected)="opcionSeleccionadaAlmacenGasto($event)">
              <ng-container *ngFor="let option of listaTodosTransportistas">
                <mat-option [value]="option.descripcionTransportista"
                  *ngIf="option.descripcionTransportista?.toLowerCase().includes(textoFiltro?.toLowerCase())">
                  {{ option.descripcionTransportista }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="SedebeverAereo">
            <mat-label>Ruta</mat-label>
            <input type="text" matInput formControlName="rutaCosto">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="SedebeverAereo">
            <mat-label>Frecuencia</mat-label>
            <input type="text" matInput formControlName="frecuenciaCosto">
          </mat-form-field>

          <button *ngIf="pricing" (click)="AgregarItem('Costo')" class="custom-button">ADD</button>
        </form>
        <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
          <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;">
            <!--CONTAINER TABLE-->
            <app-table *ngIf="pricing" [dataTable]="dataCosto" [metadataTable]="metaDataTableCosto">
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" class="cell">
                  <button mat-button type="button" class="hover-icon-button" (click)="editarItemcosto(row)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button mat-button type="button" (click)="deletecosto(row)" class="hover-icon-button">
                    <mat-icon class="delete-icon">delete</mat-icon></button>
                </mat-cell>
              </ng-container>
            </app-table>
            <app-table *ngIf="!pricing" [dataTable]="dataCosto" [metadataTable]="metaDataTableCosto2">
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" class="cell">
                  <button mat-button type="button" class="hover-icon-button" (click)="editarItemcosto(row)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button mat-button type="button" (click)="deletecosto(row)" class="hover-icon-button">
                    <mat-icon class="delete-icon">delete</mat-icon></button>
                </mat-cell>
              </ng-container>
            </app-table>
          </div>
        </div>
      </div>
      <div>
        <div class="section-title">
          <h1>Gastos Adicionales</h1>
        </div>
        <form class="label" [formGroup]="tabGasto" (keydown.enter)="$event.preventDefault()">
          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Tipo Gasto</mat-label>
            <mat-select formControlName="tipoGasto">
              <mat-option *ngFor="let tGasto of listGastoTipoGasto"
                [value]="tGasto.codigo">{{tGasto.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline">
            <mat-label>Concepto</mat-label>
            <mat-select formControlName="conceptoGasto">
              <mat-option *ngFor="let gConcepto of listGastoConcepto"
                [value]="gConcepto.codigo">{{gConcepto.nombre}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Costo</mat-label>
            <input type="number" matInput formControlName="costo" min="0">
          </mat-form-field>

          <mat-form-field class="example-full-width-alterno4" appearance="outline">
            <mat-label>Venta</mat-label>
            <input type="number" matInput formControlName="monto" min="0">
          </mat-form-field>



          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="false">
            <mat-label>TDI</mat-label>
            <input type="number" matInput formControlName="tdiGasto">
          </mat-form-field>


          <mat-form-field class="example-full-width-alterno4" appearance="outline" *ngIf="false">
            <mat-label>Almacen gasto</mat-label>
            <input type="number" matInput formControlName="almacen">
          </mat-form-field>


          <mat-form-field class="example-full-width-alterno3" appearance="outline" *ngIf="false">
            <mat-label>Aereolinea</mat-label>
            <input type="string" matInput formControlName="aereolineaGasto" [matAutocomplete]="autoAerolinea"
              [(ngModel)]="textoFiltro">
            <mat-autocomplete #autoAerolinea="matAutocomplete"
              (optionSelected)="opcionSeleccionadaAlmacenGasto($event)">
              <ng-container *ngFor="let option of listaTodosTransportistas">
                <mat-option [value]="option.descripcionTransportista"
                  *ngIf="option.descripcionTransportista?.toLowerCase().includes(textoFiltro?.toLowerCase())">
                  {{ option.descripcionTransportista }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>


          <mat-form-field class="example-full-width-alterno3" appearance="outline">
            <mat-label>Nota</mat-label>
            <input type="string" matInput formControlName="notaGasto">
          </mat-form-field>


          <mat-checkbox *ngIf="SedebeverAereo" ng-model="checked" formControlName="pesoC">Aplica Peso
            Cargable</mat-checkbox>

          <!------------------------------------>
          <!-- <mat-form-field class="example-full-width-alterno3" appearance="outline">
                <mat-label>Transportista</mat-label>
                <input
                type="text"
                matInput
                formControlName="transportistaCont"
                [matAutocomplete]="autoTransportista"
                [(ngModel)]="textoFiltro"
              >
              <mat-autocomplete #autoTransportista="matAutocomplete" (optionSelected)="opcionSeleccionada($event)">
                <ng-container *ngFor="let option of listaTodosTransportistas">
                  <mat-option [value]="option.descripcionTransportista" *ngIf="option.descripcionTransportista?.toLowerCase().includes(textoFiltro?.toLowerCase())">
                    {{ option.descripcionTransportista }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              </mat-form-field> -->

          <!--------------------------------------->




          <button class="custom-button" *ngIf="pricing" (click)="AgregarItem('Gasto')">ADD</button>
          <button class="custom-button" *ngIf="editaGas" (click)="actualizaGastoTabla()">UPDATE</button>
        </form>
        <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px">
          <div fxFlex="50" style="background-color: rgb(219, 217, 217); padding: 1px;">
            <!--CONTAINER TABLE-->
            <app-table *ngIf="pricing" [dataTable]="dataGasto" [metadataTable]="metaDataTableGasto">
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row; let i = index" class="cell">
                  <button mat-button type="button" class="hover-icon-button" (click)="editarItemgasto(row)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button mat-button type="button" (click)="deletegasto(row)" class="hover-icon-button">
                    <mat-icon class="delete-icon">delete</mat-icon></button>
                </mat-cell>
              </ng-container>
            </app-table>
            <app-table *ngIf="!pricing" [dataTable]="dataGasto" [metadataTable]="metaDataTableGasto2">
              <ng-container matColumnDef="actions">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="header-cell"></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row; let i = index" class="cell">
                  <button mat-button type="button" class="hover-icon-button" (click)="editarItemgasto(row)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button mat-button type="button" (click)="deletegasto(row)" class="hover-icon-button">
                    <mat-icon class="delete-icon">delete</mat-icon></button>
                </mat-cell>
              </ng-container>
            </app-table>
          </div>
        </div>
      </div>
    </form>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Adjunto
    </ng-template>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0px" fxLayoutAlign="center" style="margin-top: 10px;">
      <div fxFlex="100" style="padding: 2px;">
        <!-- <app-agrega-archivo></app-agrega-archivo>-->

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center" style="margin-top: 10px;">
          <div class="archivos-container" fxLayout="column" fxLayoutAlign="start">
            <div *ngFor="let archivo of archivosSeleccionado; let i = index" class="archivo-item">
              <div style="display: flex;  background-color: #f0f0f0; padding: 5px; width: 100%;">
                <img [src]="obtenerIconoTipoArchivo(archivo.name)"
                  style="width: 24px; height: 24px; margin-right: 10px;">
                <span class="archivo-name">{{ archivo.name }}</span>
                <button class="custom-button descargar-button" (click)="descargarArchivo(archivo)">
                  <i class="fas fa-download"></i>
                </button>
                <button class="custom-button quitar-button" (click)="quitarArchivo(i)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button (click)="agregarArchivo()" class="custom-button">
          <i class="fas fa-file-upload"></i> Carga archivo
        </button>
      </div>
    </div>
  </mat-tab>

</mat-tab-group>